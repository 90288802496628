import { useEffect, useState } from "react";
import RefreshIcon from "../assets/images/refresh.png";

import Xls from "../assets/images/xls.png";
import * as XLSX from "xlsx";
import axios from "../axiosConfig";

function AdminPanel() {
  const [tabledata, setTabledata] = useState([]);
  const resortsDevelopmentData = async () => {
    try {
      const token = localStorage.getItem("token"); // Retrieve the token from localStorage or your storage mechanism

      const response = await axios.get("api/home/GetRegistrationInfo", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status !== 200) {
        throw new Error(`Request failed with status ${response.status}`);
      }

      setTabledata(response.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    resortsDevelopmentData();
  }, []);

  const exportToExcel = () => {
    const table = document.getElementById("data-table");
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "info" + ".xlsx");
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  }, []);

  const hasAttendedAmount = tabledata?.filter(
    (x) => x.hasAttended === true
  )?.length;

  return (
    <div
      className="container mt-5 mb-5"
      style={{ paddingTop: "50px", color: "white" }}
    >
      <button className="fresh-button" onClick={() => resortsDevelopmentData()}>
        <img
          src={RefreshIcon}
          className="mb-1"
          width={"20px"}
          height={"20px"}
        />
      </button>
      <button
        className="btn btn-outline-success float-end"
        onClick={exportToExcel}
      >
        Download{" "}
        <img src={Xls} className="mb-1" width={"20px"} height={"20px"} />
      </button>
      <div
        className="table-responsive mt-3"
        style={{ width: "100%", overflowY: "auto", maxHeight: "550px" }}
      >
        <table id="data-table" className="table table-striped table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Surname</th>
              <th scope="col">Country</th>
              <th scope="col">Organization</th>
              <th scope="col">Position</th>
              <th scope="col">MobileNumber</th>
              <th scope="col">Email</th>
              <th scope="col">Has attended</th>
            </tr>
          </thead>
          <tbody>
            {tabledata?.map((x, index) => {
              return (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{x.name}</td>
                  <td>{x.surname}</td>
                  <td>{x.country}</td>
                  <td>{x.organization}</td>
                  <td>{x.position}</td>
                  <td>{x.mobileNumber}</td>
                  <td>{x.email}</td>
                  <td>{x.hasAttended ? "Yes" : "No"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="row">
        <div className="col-12">
          <h4 className="mt-3">Total Amount: {tabledata?.length}</h4>
        </div>
        <div className="col-12">
          <h4 className="mt-3">
            Total Number of attendees: {hasAttendedAmount}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
