import Pin from "../assets/images/pin.png";
import Email from "../assets/images/email.png";
import Call from "../assets/images/call.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { useEffect, useState } from "react";

import {
  countriesEng,
  countriesGeo,
  countriesList,
} from "../constants/countries";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

import Georgia from "../assets/images/geoPic.jpg";

import RightArrow from "../assets/images/right-arrow.png";
import StrokesBlur from "../assets/images/Brandbook - full version - 16.10.2023-01.png";

import CloseIcon from "../assets/images/close (1).png";

import Toba from "../assets/images/Tobavarchkjhili.jpg";
import Toba1 from "../assets/images/Background-mob-3.jpg";
import Toba2 from "../assets/images/geoPicMobile.jpg";

import { Modal, Toast, ToastBody } from "react-bootstrap";
import { speakersArrEng, speakersArrGeo } from "../constants/speakers";
import { agendaEng, agendaGeo } from "../constants/agenda";
import { welcomeArrEng, welcomeArrGeo } from "../constants/welcome-guests";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import BackgrounHalf from "../assets/images/Brandbook444.png";
import { Navigate, useNavigate } from "react-router-dom";
import YouTubeVideo from "../components/youtube";

////////////////////////////// import 2023 pics

import pic1 from "../assets/2023-galerry/_42A2866.jpg";
import pic2 from "../assets/2023-galerry/_42A2867.jpg";
import pic3 from "../assets/2023-galerry/_42A2902.jpg";
import pic4 from "../assets/2023-galerry/_42A2912.jpg";
import pic5 from "../assets/2023-galerry/_42A2983.jpg";
import pic6 from "../assets/2023-galerry/_42A2991.jpg";
import pic7 from "../assets/2023-galerry/_42A2997.jpg";
import pic8 from "../assets/2023-galerry/_42A3013.jpg";
import pic9 from "../assets/2023-galerry/_42A3170.jpg";
import pic10 from "../assets/2023-galerry/_42A3185.jpg";
import pic11 from "../assets/2023-galerry/_42A3238.jpg";
import pic12 from "../assets/2023-galerry/_42A3292.jpg";
import pic13 from "../assets/2023-galerry/_42A3313.jpg";
import pic14 from "../assets/2023-galerry/_42A3345.jpg";
import pic15 from "../assets/2023-galerry/_42A3573.jpg";
import pic16 from "../assets/2023-galerry/_42A3600.jpg";
import pic17 from "../assets/2023-galerry/_42A3645.jpg";
import pic18 from "../assets/2023-galerry/_42A3652.jpg";
import pic19 from "../assets/2023-galerry/_42A3682.jpg";
import pic20 from "../assets/2023-galerry/_42A3763.jpg";
import pic21 from "../assets/2023-galerry/_42A3772.jpg";
import pic22 from "../assets/2023-galerry/_42A3797.jpg";
import pic23 from "../assets/2023-galerry/_42A3841.jpg";
////////////////////////////////////////

const registrationValue = "XX417X37-89MT4TGD-JFUSFQ18-NBGN611A";
const isMobileSize = () => window.innerWidth <= 768;

function Home2023() {
  const { i18n, t } = useTranslation();
  const [isMobile, setIsMobile] = useState(isMobileSize());
  const [loading, setLoading] = useState(false);

  const handleResize = () => {
    setIsMobile(isMobileSize());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const [welcomeArr, setWelcomeArr] = useState([]);
  const [speakersArr, setSpeakersArr] = useState([]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settingsW = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [country, setCountry] = useState("საქართველო");
  const [organization, setOrganization] = useState("");
  const [position, setPosition] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setCountry("საქართველო");
  }, [i18n.language]);

  const AddRegistrationInfo = async () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsValidEmail(emailPattern.test(email));
    if (!emailPattern.test(email)) return;

    try {
      setLoading(true);
      const response = await axios.put(
        "api/home/AddRegistrationInfo",
        {
          name: name,
          surname: surname,
          country: country,
          organization: organization,
          position: position,
          mobileNumber: countryCode + " " + mobileNumber,
          email: email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoading(false);
      if (response.status === 200) {
        localStorage.setItem(registrationValue, true);
        setLocalStorageValue(registrationValue);
      }
    } catch (error) {
      toast.error("Something went wrong", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setLoading(false);
    }
  };

  const [localStorageValue, setLocalStorageValue] = useState(
    localStorage.getItem(registrationValue) || ""
  );
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (
        event.key === registrationValue &&
        event.newValue !== localStorageValue
      ) {
        setLocalStorageValue(event.newValue);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [localStorageValue]);

  const [showModal, setShowModal] = useState(false);
  const [speakerImage, setSpeakerImage] = useState("");

  // Function to toggle modal visibility
  const handleModalToggle = (desc, name, img) => {
    setShowModal(!showModal);
    setSpeakerDesc(desc);
    setSpeakerName(name);
    setSpeakerImage(img);
  };

  const [agenda, setAgenda] = useState([]);
  const [displayMore, setDisplayMore] = useState(3);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const [ref1, inView1] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const [ref2, inView2] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const [ref3, inView3] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  useEffect(() => {
    setAgenda(i18n.language === "ka" ? agendaGeo : agendaEng);
    setSpeakersArr(i18n.language === "ka" ? speakersArrGeo : speakersArrEng);
    setWelcomeArr(i18n.language === "ka" ? welcomeArrGeo : welcomeArrEng);
  }, [i18n.language]);

  const [isValidEmail, setIsValidEmail] = useState(true);

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);
  };

  const [smallText, setSmallText] = useState(false);
  const [smallText2, setSmallText2] = useState(false);

  const [speakerName, setSpeakerName] = useState("");
  const [speakerDesc, setSpeakerDesc] = useState("");

  const [isFirstText, setIsFirstText] = useState(true);

  const sortedCountries = countriesList.slice().sort((a, b) => {
    return i18n.language === "ka"
      ? a.nameGE.localeCompare(b.nameGE)
      : a.nameEN.localeCompare(b.nameEN);
  });

  const countryCode =
    i18n?.language === "ka"
      ? countriesList?.find((x) => x.nameGE === country)?.code
      : countriesList?.find((x) => x.nameEN === country)?.code;

  useEffect(() => {
    setCountry(i18n?.language === "ka" ? "საქართველო" : "");
  }, [i18n.language]);

  return (
    <div className="home" style={{ color: "white" }}>
      <div className="background-container-2023">
        <div
          className="overlay"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div className="content container text-center">
            {/* <div
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
              className="conference-2024-container mx-auto mt-5"
            >
              <h4 class="pt-2">{t("topbarHome")}</h4>
            </div> */}
            <h1
              style={{ fontSize: "33px" }}
              dangerouslySetInnerHTML={{
                __html: t("backgroundImageTitle2023"),
              }}
            />
            <h1
              className="mt-5"
              dangerouslySetInnerHTML={{ __html: t("backgroundImageTitle2") }}
            />
            <div className="row pt-5">
              <div className="col-12">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: t("backgroundImageDate2023"),
                  }}
                />
              </div>
              {/* <div className="col-12 mt-5">
                <a href="#register">
                  <button
                    className="btn btn-secondary register-now-button"
                    style={{ textTransform: "uppercase" }}
                  >
                    {t("backgroundImageRegister")}
                  </button>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div id="conferance" style={{ visibility: "hidden" }}></div>
      <div id="conferance-2024" className="pt-3">
        <div className="pb-5 ms-md-0 ms-3 me-md-0 me-3">
          <h3
            style={{ color: "#fcd429" }}
            className={`container text-end pt-5 animated-text ${
              inView ? "in-view-right" : ""
            }`}
            ref={ref}
          >
            {t("navbarConferance")}
          </h3>
          <div className="container-background-2024 mb-5">
            <div
              className={`conferance-block-2024 container animated-text ${
                inView ? "in-view-left" : ""
              }`}
              ref={ref}
            >
              <b>{t("conferanceMission")}</b> <br /> <br />
              <div dangerouslySetInnerHTML={{ __html: t("conferance1") }} />
              <br />
              {smallText && (
                <div>
                  <br /> {t("conferance2")}
                  <br />{" "}
                  <div dangerouslySetInnerHTML={{ __html: t("conferance3") }} />
                </div>
              )}
              <div
                className="cursor-pointer mt-3"
                onClick={() => setSmallText((item) => !item)}
              >
                {smallText === true ? t("seeLess") : t("raedMore")}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div>
        <div className="background-inner-container">
          <img
            className="background-image"
            src={Georgia}
            alt="Background Image"
          />
          <div
            className={`content v43 animated-text ${
              inView1 ? "in-view-top" : ""
            }`}
            ref={ref1}
          >
            <h1 className="pb-3 pt-3">{t("georgiaTitle")}</h1>
            <h5 dangerouslySetInnerHTML={{ __html: t("georgiaInfo1") }} />
            {smallText2 && (
              <h5 dangerouslySetInnerHTML={{ __html: t("georgiaInfo2") }} />
            )}
            <div
              className="cursor-pointer mt-3"
              onClick={() => setSmallText2((item) => !item)}
            >
              {smallText2 === true ? t("seeLess") : t("raedMore")}
            </div>
          </div>
        </div>
      </div> */}
      <div className="text-center mt-5">
        <h3 style={{ color: "#fcd429" }}>{t("welcomeGuys")}</h3>
        <div
          className="container p-sm-5 p-0 speakers-container"
          style={{ overflow: "hidden" }}
        >
          <Slider {...settingsW}>
            {welcomeArr?.map((item) => (
              <div className="text-center" key={item?.id}>
                <img
                  className="mx-auto text-center"
                  src={item?.img}
                  width="280px"
                  alt=""
                  style={{ borderRadius: "50%", cursor: "pointer" }}
                  onClick={() =>
                    handleModalToggle(item?.description, item?.name, item?.img)
                  }
                />
                <h4
                  className="hover-info mt-2"
                  onClick={() =>
                    handleModalToggle(item?.description, item?.name, item?.img)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {item?.name}
                </h4>

                <p dangerouslySetInnerHTML={{ __html: item.position }} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div className="text-center pb-5 pt-sm-0 pt-5">
        <h3 style={{ color: "#fcd429" }}>{t("speakers2")}</h3>
        <div className="speakers-container" style={{ overflow: "hidden" }}>
          <Slider {...settings}>
            {speakersArr?.map((item) => (
              <div className="text-center p-4" key={item?.id}>
                <img
                  src={item?.img}
                  width="280px"
                  alt=""
                  className="mx-auto text-center"
                  style={{ borderRadius: "50%", cursor: "pointer" }}
                  onClick={() =>
                    handleModalToggle(item?.description, item?.name, item?.img)
                  }
                />
                <h4
                  className="hover-info mt-2"
                  onClick={() =>
                    handleModalToggle(item?.description, item?.name, item?.img)
                  }
                  style={{ cursor: "pointer" }}
                >
                  {item?.name}
                </h4>

                <p dangerouslySetInnerHTML={{ __html: item.position }} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <div id="program" style={{ visibility: "hidden" }}></div>
      <div id="program" className="mt-3">
        <div
          className="mx-auto justify-content-center row pb-5 p-3 p-sm-0 "
          //   inView2 ? "in-view-bottom" : ""}`
          // ref={ref2}
          style={{
            backgroundImage:
              "linear-gradient(to right, rgb(18, 27, 59), rgb(105, 100, 100))",
          }}
        >
          <div className="container-background-2024">
            <div className="col-lg-9 col-md-11 mx-auto">
              <div>
                <h3 className="pt-md-5" style={{ color: "#fcd429" }}>
                  {t("navbarProgram")}
                </h3>
              </div>
              {agenda?.slice(0, displayMore).map((x, i) => {
                return (
                  <div
                    className="pt-sm-5 pb-sm-5 hover-class-2024 p-2 row"
                    key={i}
                    style={{ borderBottom: "1px solid " }}
                  >
                    <div className="col-sm-3">
                      <h5>{x.date}</h5>
                    </div>
                    <div className="col-sm-9">
                      <h5 dangerouslySetInnerHTML={{ __html: x.title }} />
                      <div
                        className="col-sm-9"
                        dangerouslySetInnerHTML={{ __html: x?.parag }}
                      />
                      <ul className="ms-3">
                        {x?.list?.map((item, index) => {
                          return (
                            <li key={index}>
                              <b dangerouslySetInnerHTML={{ __html: item }} />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                );
              })}
              <h5
                className="cursor-pointer mt-3 mb-5"
                onClick={() =>
                  setDisplayMore((prev) => (prev <= 3 ? agenda?.length : 3))
                }
                style={{ cursor: "pointer" }}
              >
                {displayMore !== 3 ? t("seeLess") : t("raedMore")}
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div id="orginizor" style={{ visibility: "hidden" }}></div>
      {/* <div id="orginizor" className="pt-3">
        <div className="container mt-5 mb-5">
          <div className="row">
            <div
              className="div-move-arrow col-md-8 mx-auto p-sm-2 p-4"
              style={{ color: "black" }}
            >
              <h3 className="pb-3" ref={ref3}>
                {t("navbarOrg")}
              </h3>
              {isFirstText && (
                <div
                  style={{ textAlign: "left" }}
                  ref={ref3}
                  dangerouslySetInnerHTML={{ __html: t("orgParag") }}
                />
              )}
              {!isFirstText && (
                <div
                  style={{ textAlign: "left" }}
                  ref={ref3}
                  dangerouslySetInnerHTML={{ __html: t("orgParag1") }}
                />
              )}
              <img
                className="float-end move-arrow"
                onClick={() => {
                  isFirstText ? setIsFirstText(false) : setIsFirstText(true);
                }}
                src={RightArrow}
                width={"50px"}
                style={{
                  cursor: "pointer",
                  transform: isFirstText ? "" : "rotate(180deg)",
                }}
                alt=""
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* <div id="register" className="pb-5">
        <div className="background-inner-container">
          <img
            className="background-image"
            src={StrokesBlur}
            alt="Background Image"
          />
          <div className="content row mx-auto" style={{ color: "black" }}>
            <h1 className="pb-md-5" style={{ color: "black" }}>
              {t("navbarReg")}
            </h1>
            {localStorageValue && (
              <div className="text-center">
                <h2 style={{ color: "black" }} className="mt-3">
                  {t("successMess")}
                </h2>
                <h4 className="mt-4 mx-auto" style={{ color: "black" }}>
                  {t("successMessQR")}
                </h4>
              </div>
            )}
            {!localStorageValue && (
              <>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="Name1" className="form-label float-start">
                      {t("name")}
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      id="Name1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="surname2"
                      className="form-label float-start"
                    >
                      {t("surname")}
                    </label>
                    <input
                      type="text"
                      value={surname}
                      onChange={(e) => setSurname(e.target.value)}
                      className="form-control"
                      id="surname2"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="ff" className="form-label float-start">
                      {t("country")}
                    </label>
                    <select
                      className="form-select"
                      id="countrySelector"
                      onChange={(e) => setCountry(e.target.value)}
                      value={country}
                    >
                      <option value={""} hidden>
                        {}
                      </option>
                      {sortedCountries?.map((item, index) => (
                        <option
                          key={index}
                          value={
                            i18n.language !== "ka" ? item?.nameEN : item?.nameGE
                          }
                        >
                          {i18n.language !== "ka" ? item?.nameEN : item?.nameGE}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="email1" className="form-label float-start">
                      {t("email")}
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmailChange}
                      className="form-control"
                      id="email1"
                      pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                      title="Enter a valid email address"
                      required
                    />
                    {!isValidEmail && (
                      <span className="text-danger float-start mt-3 mb-3">
                        {t("invalidEmail")}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-3">
                    <label
                      htmlFor="organization1"
                      className="form-label float-start"
                    >
                      {t("organization")}
                    </label>
                    <input
                      type="text"
                      value={organization}
                      onChange={(e) => setOrganization(e.target.value)}
                      className="form-control"
                      id="organization1"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="position2"
                      className="form-label float-start"
                    >
                      {t("position")}
                    </label>
                    <input
                      type="text"
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                      className="form-control"
                      id="position2"
                      aria-describedby="emailHelp"
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="mobileNumber2"
                      className="form-label float-start"
                    >
                      {t("mobileNumber")}
                    </label>
                    <span
                      className="input-group-text"
                      id="countryCode"
                      style={{
                        position: "fixed",
                        marginTop: "33px",
                        background: "transparent",
                        border: "none",
                      }}
                    >
                      {countryCode ? countryCode : ""}
                    </span>
                    <input
                      type="text"
                      style={{ paddingLeft: "60px" }}
                      value={mobileNumber}
                      disabled={
                        country === "" ||
                        country === undefined ||
                        country === null
                      }
                      onChange={(e) => setMobileNumber(e.target.value)}
                      className="form-control"
                      id="mobileNumber2"
                      aria-describedby="countryCode"
                    />
                  </div>
                </div>
                <button
                  className="btn btn-secondary register-now-button register-v4 mx-auto mt-md-5 mt-3"
                  style={{ maxWidth: "250px", color: "black" }}
                  onClick={() => AddRegistrationInfo()}
                  disabled={
                    name === "" ||
                    surname === "" ||
                    country === "" ||
                    organization === "" ||
                    position === "" ||
                    mobileNumber === "" ||
                    email === "" ||
                    loading === true
                  }
                >
                  {!loading && t("backgroundImageRegister")}
                  {loading && <div className="loader mx-auto"></div>}
                </button>
              </>
            )}
          </div>
        </div>
      </div> */}
      <div className="gallery">
        <div className="row mx-auto">
          <div className="column">
            <img src={pic1} style={{ width: "100%" }} />
            <img src={pic2} style={{ width: "100%" }} />
            <img src={pic3} style={{ width: "100%" }} />
            <img src={pic4} style={{ width: "100%" }} />
            <img src={pic5} style={{ width: "100%" }} />
            <img src={pic6} style={{ width: "100%" }} />
          </div>
          <div className="column">
            <img src={pic7} style={{ width: "100%" }} />
            <img src={pic8} style={{ width: "100%" }} />
            <img src={pic9} style={{ width: "100%" }} />
            <img src={pic10} style={{ width: "100%" }} />
            <img src={pic11} style={{ width: "100%" }} />
            <img src={pic12} style={{ width: "100%" }} />
          </div>
          <div className="column">
            <img src={pic13} style={{ width: "100%" }} />
            <img src={pic14} style={{ width: "100%" }} />
            <img src={pic15} style={{ width: "100%" }} />
            <img src={pic16} style={{ width: "100%" }} />
            <img src={pic17} style={{ width: "100%" }} />
            <img src={pic18} style={{ width: "100%" }} />
          </div>
          <div className="column">
            <img src={pic19} style={{ width: "100%" }} />
            <img src={pic20} style={{ width: "100%" }} />
            <img src={pic21} style={{ width: "100%" }} />
            <img src={pic22} style={{ width: "100%" }} />
            <img src={pic23} style={{ width: "100%" }} />
          </div>
        </div>
      </div>
      {/* <YouTubeVideo /> */}
      <div className="google-map pt-5 pb-5 pb-3 p-2">
        <h3 className="text-center">{t("mapTitle")}</h3>
        <h4 className="text-center">
          <p>{t("mapAddressParag")}</p>
        </h4>
        <h4 className="text-center">
          <p>
            <img
              src={Pin}
              alt=""
              className="me-1"
              width={"24px"}
              height={"24px"}
            />{" "}
            {t("mapAddress")}
          </p>
        </h4>
        <div className="container">
          <a href="https://www.google.com/maps?ll=41.688645,44.8231&amp;z=15&amp;t=m&amp;hl=en&amp;gl=GE&amp;mapclient=embed&amp;cid=11604866194380701474">
            <iframe
              className="mt-5 map_iframe"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11917.906578471317!2d44.8231!3d41.688645!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40440d393e031453%3A0xa10cc4518e003f22!2sSheraton%20Grand%20Tbilisi%20Metechi%20Palace!5e0!3m2!1sen!2sge!4v1689672436687!5m2!1sen!2sge"
              width="100%"
              height="240"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              sandbox="allow-scripts allow-same-origin allow-popups allow-top-navigation allow-forms"
              x-frame-options="same-origin"
            ></iframe>
          </a>
        </div>
      </div>
      {/* <div id="contact">
        <div className="background-inner-container" style={{ height: "460px" }}>
          <div className="content">
            <h3 className="ps-4 pe-4" style={{ color: "#fcd429" }}>
              {t("navbarContact")}
            </h3>
            <div
              className="contact-container p-sm-5 p-3 mt-3 m-3"
              style={{ color: "white" }}
            >
              <h5 className="mb-3">{t("companyNameG")}</h5>
              <div className="p-2">
                <p>
                  <img
                    src={Pin}
                    alt=""
                    className="me-1"
                    width={"16px"}
                    height={"16px"}
                  />{" "}
                  {t("contactAddress")}
                </p>
                <p>
                  <img
                    src={Call}
                    className="me-2"
                    alt=""
                    width={"16px"}
                    height={"16px"}
                  />
                  +995 32 2 50 03 38
                </p>
                <p>
                  <img
                    src={Email}
                    className="me-2"
                    alt=""
                    width={"16px"}
                    height={"16px"}
                  />
                  conference@resorts.gov.ge
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div>
        <Modal
          show={showModal}
          onHide={handleModalToggle}
          centered
          size="xl"
          style={{ borderRadius: "30px !important" }}
        >
          <Modal.Header>
            <img
              src={CloseIcon}
              alt=""
              onClick={() => setShowModal(false)}
              width="20px"
              style={{ cursor: "pointer" }}
              className="justify-content-end"
            />
          </Modal.Header>
          <Modal.Body>
            <div className="row text-center">
              <div className="col-12">
                <img src={speakerImage} alt="" width={"150px"} />
              </div>
              <div className="col-12 mt-2">
                <Modal.Title>{speakerName}</Modal.Title>
              </div>
            </div>
            <hr />
            <p
              className="p-3 modal-body-v4"
              dangerouslySetInnerHTML={{ __html: speakerDesc }}
            />
          </Modal.Body>
        </Modal>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Home2023;
