export const agendaEng = [
  {
    id: 0,
    date: "09:30 - 10:30",
    title: `Registration of participants <br />
      Welcome Coffee`,
  },
  {
    id: 1,
    date: "10:30 - 11:00",
    title: `Welcoming address: <br /><br />
    Levan Davitashvili - Vice Prime Minister, Minister of Economy and Sustainable Development of Georgia <br /><br />
    Aleksandre Onoprishvili - Director, LEPL Resorts Development Agency`,
  },
  {
    id: 2,
    date: "11:00 - 12:10",
    title:
      "Session 1: Resorts Sustainable Development: Trends, Challenges and Future Prospects",
    parag: `
    The session will highlight the dynamic landscape of climate and balneological resorts sustainable
    development. It examines current trends, identifies challenges, and envisions future prospects for
    the sustainable growth and evolution of these leisure destinations. The analysis sheds light on key
    strategies and innovations driving sustainable practices in resorts and highlights the obstacles that
    need to be overcome to ensure a more environmentally and socially responsible future for the
    resort industry. The session provides insights into the multifaceted aspects of resorts' sustainable
    development and offers a glimpse into the potential pathways for their long-term success.
    <br /><br /> 
    <b>Welcome Speech:</b> <br /><br /> 
    <b>Tamar Gabunia</b> - First Deputy Minister, Ministry of Internally Displaced Persons from the
Occupied Territories, Labour, Health and Social Affairs of Georgia</b> <br /><br />

<b>Markos Danas</b> - Vice President, European Historic Thermal Towns Association (EHTTA)

<br /><br /> <b>  Moderator: <br />
PhD Candidate Lela Potskhverashvili - Deputy Director, LEPL Resorts Development Agency <br /><br />
Speakers:</b> <br />
`,
    list: [
      `Maia Omiadze - Head, LEPL Georgian National Tourism Administration`,
      `Davit Tabidze - Director, LEPL Municipal Development Fund of Georgia`,
      `Ioane Menabde - Director, LEPL Spatial and Urban Development Agency`,
      `Mikheil Khidureli - Director, LEPL Enterprise Georgia`,
    ],
  },
  {
    id: 3,
    date: "12:10 - 12:30",
    title: "Coffee Break",
  },
  {
    id: 4,
    date: "12:30 - 14:00",
    title: "Session 2: Economic and Social Impacts of Resorts Development",
    parag: `The session will present the multifaceted economic and social impacts of the climate and
    balneological resorts development. It highlight effects that the development of resorts have on
    both local and broader economies, including investment and employment opportunities, revenue
    generation and regional economic growth. Furthermore, session will disclose the social
    consequences, examining factors such as cultural preservation, community engagement and
    quality of life for residents and visitors. The session will provide valuable insight about the
    sustainable development and management of resorts. <br /><br /> <b>  Moderator: <br />
    Prof. Gigi Kuparadze - Head of Tourism Programme, Grigol Robakidze University<br /><br />
    Speakers:</b> <br />
    `,
    list: [
      `Jean-Marc FARINNI - CDA French & International Development Director, President of CDA
    Management`,
      `MORI Hiroyuki - Resident Representative, Japan International Cooperation Agency (JICA) Georgia Office`,
      `Florian Van der Bellen - Director and Global Head of Destination Development, PKF hospitality
      group`,
      `Markos Danas - Vice President, European Historic Thermal Towns Association (EHTTA) <br />
      "European Spa Culture – New Opportunities for Thermal Tourism"`,
      `Natia Tevzadze - CEO, Physical Medicine, Balneology and Resortology Association of Georgia`,

      /////////////////////
      // `Presentation of the Project REVIVE (Portugal) TBC`,
    ],
  },
  {
    id: 5,
    date: "14:00 - 15:00",
    title: "Lunch",
  },
  {
    id: 6,
    date: "15:00 - 17:00",
    title: "Session 3: Wellness and Balneology in Healthcare and Wellbeing",
    parag: `The Session will explore wellness and balneology in the context of healthcare and overall well-being
    and present concepts, local and international practices and their impact on promoting health and
    enhancing the quality of life. It offers current trends, challenges and potential future directions in
    utilizing wellness and balneology for the improvement of healthcare and well-being. Moreover,
    session will offer a comprehensive perspective of resorts development and examines therapeutic
    approaches, exploring how wellness practices and balneological therapies contribute to the
    enhancement of individuals’ health and quality of life. <br /><br /> <b>  Moderator: <br />
    Prof. Khatuna Saganelidze - Expert of Balneology, LEPL Resorts Development Agency <br /><br />
       Speakers:</b> <br />
       `,
    list: [
      `M.D. Ladislav Spisak - Chairman, Council of the Karlovy-Vary Institute of Balneology<br />
      "New Trends in Balneology after Pandemic COVID-19"`,
      `Dr. Gerard Andre - Managing Director, Tempo Hospitality Consulting SA<br />
      "Water, current and future vector of quality of life, health and well-being"`,
      `Associate Professor Sergio Gianesini - MD, PhD, FACS, University of Ferrara, Italy;<br />
      University of Health Sciences in Bethesda, USA<br />
      "SPA: from Salus to SCIENCE Per Aquam"
      `,
      `Tamar Chilingarashvili - Professor, Rheumatologist, Balneo Therapist, Tbilisi Balneological Spa
      Resort
      `,
      `Nino Chaduneli - The winner of the Students’ Scientific Conference <br />"TRENDS AND
      APPROACHES OF THE RESORTS SUSTAINABLE DEVELOPMENT"
      `,
    ],
  },
  {
    id: 7,
    date: "17:00",
    title: "End of the Programme",
  },
];
export const agendaGeo = [
  {
    id: 0,
    date: "09:30 - 10:30",
    title: `მონაწილეთა რეგისტრაცია<br />
    მიღება`,
  },
  {
    id: 1,
    date: "10:30 - 11:00",
    title: `მისასალმებელი მიმართვა: <br /><br />
    ლევან დავითაშვილი - საქართველოს ვიცე-პრემიერი, ეკონომიკისა და
მდგრადი განვითარების მინისტრი<br /><br />
ალექსანდრე ონოფრიშვილი - სსიპ კურორტების განვითარების სააგენტოს დირექტორი`,
  },
  {
    id: 2,
    date: "11:00 - 12:10",
    title: `სესია 1: კურორტების მდგრადი განვითარება: ტენდენციები, გამოწვევები და
    პერსპექტივები`,
    parag: `
    სესია მიმოიხილავს კლიმატური და ბალნეოლოგიური კურორტების მდგრადი
    განვითარების პროცესს და წარმოაჩენს საკურორტო ინდუსტრიის ევოლუციასა და
    პროგრესთან დაკავშირებულ ტენდენციებს, გამოწვევებს და პერსპექტივებს. მსჯელობა
    კურორტების მდგრადი ფუნქციონირების სტრატეგიებსა და ინოვაციებთან ერთად,
    წარმოაჩენს დაბრკოლებებსაც, რომელთა გადაჭრაც გარემოსა და საზოგადოებისთვის
    სასარგებლო საკურორტო ინდუსტრიის მომავალს უზრუნველყოფს. სესია მოიცავს
    ინფორმაციას კურორტების მდგრადი განვითარების მრავალმხრივი ასპექტებისა და
    მოსალოდნელი გრძელვადიანი სარგებლის შესახებ.<br /><br />

    <b>მისასალმებელი მიმართვა:</b> <br /><br />

    <b>თამარ გაბუნია</b> - მინისტრის პირველი მოადგილე, საქართველოს ოკუპირებული
ტერიტორიებიდან დევნილთა, შრომის, ჯანმრთელობისა და
სოციალური დაცვის სამინისტრო <br /><br />

<b>მარკოს დანასი</b> - ევროპის ისტორიული თერმული ქალაქების ასოციაციის (EHTTA)
ვიცე-პრეზიდენტი


<br /><br /> <b>  მოდერატორი: <br />
ლელა ფოცხვერაშვილი - მეცნიერებათა კანდიდატი, სსიპ კურორტების განვითარების სააგენტოს დირექტორის
მოადგილე<br /><br />
მომხსენებლები:</b> <br />
       `,
    list: [
      `მაია ომიაძე - სსიპ საქართველოს ტურიზმის ეროვნული ადმინისტრაციის
      ხელმძღვანელი`,
      `დავით ტაბიძე - სსიპ მუნიციპალური განვითარების ფონდის დირექტორი`,
      `იოანე მენაბდე - სსიპ სივრცითი და ქალაქთმშენებლობითი განვითარების სააგენტოს
      დირექტორი`,
      `მიხეილ ხიდურელი - სსიპ აწარმოე საქართველოში, დირექტორი`,
      ,
    ],
  },
  {
    id: 3,
    date: "12:10 - 12:30",
    title: "შესვენება",
  },
  {
    id: 4,
    date: "12:30 - 14:00",
    title: "სესია 2: კურორტების განვითარების სოციალური და ეკონომიკური შედეგები",
    parag: `სესიაზე განიხილება კლიმატური და ბალნეოლოგიური კურორტების განვითარებასთან
    დაკავშირებული ეკონომიკური და სოციალური შედეგები. ხაზი გაესმება ფაქტორებს,
    რომელიც გავლენას ახდენს ადგილობრივ და რეგიონულ ეკონომიკურ განვითარებაზე,
    საინვესტიციო გარემოსა და დასაქმების შესაძლებლობებზე, შემოსავლების დინამიურ
    ზრდასა და გრძელვადიან სარგებელზე. დამატებით, სესია მიმოიხილავს ისეთ
    ფაქტორებს, რომელიც ხელს უწყობს საკურორტო ზონების კულტურული იერსახის
    შენარჩუნებას, მათ მდგრად განვითარებას, პროცესში საზოგადოების ჩართულობას,
    ადგილობრივი მოსახლეობის ცხოვრების ხარისხის გაუმჯობესებასა და ვიზიტორთა
    კმაყოფილებას. სესია მოიცავს კურორტების მდგრად განვითარებასა და მართვასთან
    დაკავშირებულ მაგალითებს. <br /><br /> <b>  მოდერატორი:<br />
    პროფესორი გიგი ყუფარაძე - გრიგოლ რობაქიძის სახელობის უნივერსიტეტის ტურიზმის
პროგრამის ხელმძღვანელი<br /><br />
მომხსენებლები:</b> <br />
       `,
    list: [
      `ჟან-მარკ ფარინი - კომპანი დეზ ალპის (CDA) საფრანგეთისა და საერთაშორისო
      განვითარების დირექტორი, CDA Management-ის პრეზიდენტი`,
      `მორი ჰიროიუკი - იაპონიის საერთაშორისო თანამშრომლობის სააგენტოს (JICA)
      საქართველოს ოფისის მუდმივი წარმომადგენელი`,
      `ფლორიან ვან დერ ბელენი - PKF hospitality group, დირექტორი`,
      `მარკოს დანასი - ევროპის ისტორიული თერმული ქალაქების ასოციაციის (EHTTA) ვიცე-
      პრეზიდენტი <br />
      "ევროპული სპა კულტურა - თერმული ტურიზმის ახალი შესაძლებლობები"`,
      `ნათია თევზაძე - საქართველოს ფიზიკური მედიცინის, ბალნეოლოგიისა და
      კურორტოლოგიის ასოციაციის აღმასრულებელი დირექტორი`,
      // `პროექტ REVIVE-ის პრეზენტაცია (პორტუგალია) TBC`,
    ],
  },
  {
    id: 5,
    date: "14:00 - 15:00",
    title: "სადილი",
  },
  {
    id: 6,
    date: "15:00 - 17:00",
    title:
      "სესია 3: ველნესი და ბალნეოლოგია ჯანმრთელობასა და ადამიანთა კეთილდღეობაში",
    parag: `სესია წარმოაჩენს ველნესისა და ბალნეოლოგიის როლს ადამიანის ჯანმრთელობასა და
    კეთილდღეობაზე. ამასთან, სესიაზე განხილული იქნება ადგილობრივი და
    საერთაშორისო მაგალითები და ველნესისა და ბალნეოლოგიის გავლენა
    ჯანმრთელობასა და ცხოვრების ხარისხზე. სესიაზე წარმოდგენილი იქნება უკვე
    დამკვიდრებულ და ინოვაციურ მიდგომებთან დაკავშირებული ტენდენციები და
    სამომავლო ხედვები. სესია წარმოაჩენს დარგის განვითარების პერსპექტივებს,
    თერაპიულ მიდგომებს და ადამიანის ჯანმრთელობასა და კეთილდღეობაში ველნესისა
    და ბალნეოლოგიური თერაპიის შედეგებს.<br /><br /> <b>  მოდერატორი:<br />
    პროფესორი ხათუნა საგანელიძე - სსიპ კურორტების განვითარების სააგენტოს
კურორტოლოგია - ბალნეოლოგიის ექსპერტი <br /><br />
მომხსენებლები:</b> <br />
       `,
    list: [
      `მედიცინის დოქტორი ლადისლავ შპიშაკი - კარლოვი ვარის ბალნეოლოგიის
      ინსტიტუტის საბჭოს თავმჯდომარე <br />
      "ახალი ტენდენციები ბალნეოლოგიაში COVID-19 პანდემიის შემდეგ"`,
      `დოქტორი ჟერარ ანდრე - Tempo Hospitality Consulting SA, მმართველი
      დირექტორი <br />"წყალი, ცხოვრების ხარისხის მიმდინარე და სამომავლო ვექტორი,
      ჯანმრთელობა და კეთილდღეობა"`,
      `ასოცირებული პროფესორი სერჯიო ჯანესინი - MD, PhD, FACS, იტალიის ფერარას
      უნივერსიტეტი; <br /> აშშ-ს ბეთესდაშის უნივერსიტეტი <br />
      "SPA: from Salus to SCIENCE Per Aquam"`,
      `თამარ ჩილინგარაშვილი - პროფესორი, თბილისის ბალნეოლოგიური კურორტის
      ექიმი-კურორტოლოგი
      `,
      `ნინო ჩადუნელი - სტუდენტთა სამეცნიერო კონფერენციის <br />„საქართველოს
      კურორტების მდგრადი განვითარების ტენდენციები და მიდგომები“ გამარჯვებული
      `,
    ],
  },
  {
    id: 7,
    date: "17:00",
    title: "პროგრამის დასასრული",
  },
];
