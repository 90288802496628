import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


function LogisticTravel() {
    const [lang, setLang] = useState("");

    const { i18n } = useTranslation();
    useEffect(() => {
        setLang(i18n.language);
    }, [i18n.language]);

    return (
        <div className="mt-5 text-center row mb-5">
            <h3
                className="pt-5"
                style={{ color: "#fcd429" }}> {lang === 'en' ? "GENERAL INFORMATION NOTE" : "ზოგადი ინფორმაცია"}
            </h3>
            {lang === 'en' ?
                <>
                    <h5 className="col-md-8 col-10 mx-auto" style={{ color: "white" }}>
                        <h1 style={{ color: "#fcd429" }} className="mt-md-5 mt-2"> Conference Date and Venue</h1>
                        The 2nd  international conference ,,The Future of Resorts Development’’ will be held on December 5, 2024 at Sheraton Grand Tbilisi Metekhi Palace.
                        Address: 20 Telavi Str., Tbilisi 0103, Georgia
                        <br /><br />For the detailed schedule and agenda of conference please visit: <a target="_blank" style={{ color: "#fcd429" }} href="https://conference.resorts.gov.ge/">conference.resorts.gov.ge</a>
                        <h1 style={{ color: "#fcd429" }} className="mt-5">Registration</h1>
                        The registration form is available on the conference website: <br /><a target="_blank" style={{ color: "#fcd429" }} href="https://conference.resorts.gov.ge/">https://conference.resorts.gov.ge/</a> <br /><br />
                        All delegates are required to complete the online registration form by November 30, 2024. Upon registration, a confirmation email with a QR code will be sent to the participant email.  All participants must present their QR code at the conference registartion counter to collect their badges. <br /><br />
                        The registration counter and information desk will be available on 5 December at the Sheraton Grand Tbilisi Metekhi Palace from 10:00 AM to 11:00 AM. <br /><br />

                        <h1 style={{ color: "#fcd429" }} className="mt-5">Sightseeing tours and Dinners</h1>

                        Sightseeing tours of Tbilisi and Mtskheta and dinners will be organized and hosted by the Resorts Development Agency for international delegates. International participants wishing to join the tour should contact the following email: <a target="_blank" style={{ color: "#fcd429" }} href="mailto:conference@resorts.gov.ge">conference@resorts.gov.ge</a>
                        <h1 style={{ color: "#fcd429" }} className="mt-5">Transportation</h1>
                        Airport transfer will be organized for the international delegates by the Resorts Development Agency. To arrange transfers, delegate should contract the following email <a target="_blank" style={{ color: "#fcd429" }} href="mailto:conference@resorts.gov.ge">conference@resorts.gov.ge</a> before 30 November.
                        Tbilisi International Airport is located 20 km from the city center and the drive takes approximately 20 minutes. Further information about  the airport can be found on the following website: <a target="_blank" style={{ color: "#fcd429" }} href="https://www.tbilisiairport.com">www.tbilisiairport.com</a>
                        <h1 style={{ color: "#fcd429" }} className="mt-5">Hotels</h1>
                        Information about hotels can be found on the website: <a target="_blank" style={{ color: "#fcd429" }} href="https://georgia.travel/">https://georgia.travel/</a>
                    </h5>
                </> : <>
                    <h5 className="col-md-8 col-10 mx-auto" style={{ color: "white" }}>
                        <h1 style={{ color: "#fcd429" }} className="mt-md-5 mt-2">კონფერენციის თარიღი და ადგილი</h1>
                        მეორე საერთაშორისო კონფერენცია 2024 წლის 5 დეკემბერს, სასტუმრო შერატონ გრანდ თბილისი მეტეხი პალასში გაიმართება. მისამართი: საქართველო, თბილისი 0103, თელავის ქ. 20
                        <br /><br />კონფერენციის პროგრამისა და დეტალური ინფორმაციისთვის ეწვიეთ:  <a target="_blank" style={{ color: "#fcd429" }} href="https://conference.resorts.gov.ge/"> https://conference.resorts.gov.ge/</a>
                        <h1 style={{ color: "#fcd429" }} className="mt-5">რეგისტრაცია</h1>
                        სარეგისტრაციო ფორმა ხელმისაწვდომია კონფერენციის ვებგვერდზე:  <br /><a target="_blank" style={{ color: "#fcd429" }} href="https://conference.resorts.gov.ge/">https://conference.resorts.gov.ge/</a> <br /><br />
                        ყველა დელეგატმა უნდა შეავსოს ონლაინ რეგისტრაციის ფორმა 2024 წლის 30 ნოემბრამდე. რეგისტრაციის დამადასტურებელი ელ-ფოსტა QR კოდით გაიგზავნება მონაწილის ელ-ფოსტაზე. ყველა მონაწილემ უნდა წარმოადგინოს თავისი QR კოდი კონფერენციის სარეგისტრაციო მაგიდასთან, რათა მიიღოს სახელობითი ბეიჯი.  <br /><br /> კონფერენციაზე დასასწრებად, გთხოვთ ადგილზე გაიაროთ რეგისტრაცია 5 დეკემბრის 10:00-დან 11:00-მდე სასტუმრო Sheraton Grand Tbilisi Metekhi Palace
                        -ში. <br /><br />

                        <h1 style={{ color: "#fcd429" }} className="mt-5">ღირშესანიშნაობების ტურები და ვახშამი</h1>

                        საერთაშორისო დელეგატებისთვის კურორტების განვითარების სააგენტოს ორგანიზებით და მასპინძლობით გაიმართება თბილისისა და მცხეთის ღირსშესანიშნაობების დათვალიერება და ვახშამი. ტურში გაწევრიანების მსურველმა საერთაშორისო მონაწილეებმა უნდა დაუკავშირდნენ შემდეგ ელ.წერილს: <a target="_blank" style={{ color: "#fcd429" }} href="mailto:conference@resorts.gov.ge">conference@resorts.gov.ge</ a>
                        <h1 style={{ color: "#fcd429" }} className="mt-5">ტრანსპორტი</h1>
                        საერთაშორისო დელეგატებისთვის აეროპორტის ტრანსფერის ორგანიზება კურორტების განვითარების სააგენტოს მიერ იქნება. ტრანსფერების მოსაწყობად დელეგატმა უნდა გააფორმოს კონტრაქტი შემდეგი ელექტრონული ფოსტით <a target="_blank" style={{ color: "#fcd429" }} href="mailto:conference@resorts.gov.ge">conference@resorts.gov.ge</a> 30 ნოემბრამდე.
                        თბილისის საერთაშორისო აეროპორტი მდებარეობს ქალაქის ცენტრიდან 20 კმ-ში და მგზავრობას დაახლოებით 20 წუთი სჭირდება. აეროპორტის შესახებ დამატებითი ინფორმაცია შეგიძლიათ იხილოთ შემდეგ ვებსაიტზე: <a target="_blank" style={{ color: "#fcd429" }} href="https://www.tbilisiairport.com">www.tbilisiairport.com</a>
                        <h1 style={{ color: "#fcd429" }} className="mt-5">სასტუმროები</h1>
                        ინფორმაცია სასტუმროების შესახებ შეგიძლიათ იხილოთ ვებგვერდზე: <a target="_blank" style={{ color: "#fcd429" }} href="https://georgia.travel/">https://georgia.travel/</a>
                    </h5>
                </>
            }
        </div>
    )
}


export default LogisticTravel;
