import axios from "axios";

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = "/admin-login";
      localStorage.setItem("token", "");
    }

    return Promise.reject(error);
  }
);

export default instance;
