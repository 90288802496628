import React from "react";
import backgroundVideo from "../assets/videos/baner.mp4";

const BackgroundVideo = () => {
  return (
    <div className="video-container">
      {/* Video Background */}
      <div className="video-background">
        <video autoPlay loop muted>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      {/* Overlay */}
      <div className="video-overlay"></div>
    </div>
  );
};

export default BackgroundVideo;
