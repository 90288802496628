import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import GeoFlag from "../assets/images/georgia.png";
import EngFlag from "../assets/images/united-kingdom.png";

import LogoGeo from "../assets/images/Main Logo-05.png";
import LogoEng from "../assets/images/Main Logo-06.png";

import LogoSamGeo from "../assets/images/Ministry Logo-08.png";
import LogoSamEng from "../assets/images/Ministry Logo-09.png";

import Logout from "../assets/images/logout.png";

import LogoGeoWhiteGeo from "../assets/images/Logo-GEO_White.png";
import LogoGeoWhiteEng from "../assets/images/Logo-ENG_White.png";

import LogoSamWhiteEng from "../assets/images/logo ministr-06.png";
import LogoSamWhiteGeo from "../assets/images/logo ministr-07.png";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function Navbar() {
  const location = useLocation();
  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("lang") || "en"
  );
  const { i18n, t } = useTranslation();

  const handleLangToggle = () => {
    const newLang = currentLang === "ka" ? "en" : "ka";
    setCurrentLang(newLang);
    localStorage.setItem("lang", newLang);
    i18n.changeLanguage(newLang);
  };

  const handleScrollToSection = (e, sectionId) => {
    e.preventDefault(); // Prevent the default anchor behavior

    // Get the element by ID
    const section = document.getElementById(sectionId);

    if (section) {
      const offset = 30; // Adjust this value to scroll a little higher
      const topPosition =
        section.getBoundingClientRect().top + window.pageYOffset - offset;

      // Scroll with smooth behavior and offset
      window.scrollTo({
        top: topPosition,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang]);

  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      // If scroll is more than 300px, change state to `true`, else `false`
      setIsScrolled(scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {/* <div className="text-center company-title">
        {location.pathname === "/conference-2023" && (
          <>
            <img
              src={currentLang === "en" ? LogoSamEng : LogoSamGeo}
              alt=""
              className="sam-pic"
              style={{
                float: "left",
                marginTop: currentLang === "en" ? "unset" : "-10px",
              }}
            />
            <img
              src={currentLang !== "en" ? LogoEng : LogoGeo}
              alt=""
              className="pic"
              style={{
                float: "left",
                marginTop: currentLang !== "en" ? "-12px" : "-6px",
              }}
            />
          </>
        )}
        {location.pathname !== "/conference-2023" && (
          <>
            <img
              src={currentLang === "en" ? LogoGeoWhiteEng : LogoGeoWhiteEng}
              alt=""
              className="sam-pic"
              style={{
                float: "left",
                marginTop: currentLang === "en" ? "unset" : "-10px",
              }}
            />
            <img
              src={currentLang !== "en" ? LogoGeoWhiteGeo : LogoGeoWhiteEng}
              alt=""
              className="pic"
              style={{
                float: "left",
                marginTop: currentLang !== "en" ? "-12px" : "-6px",
              }}
            />
          </>
        )}
        {(location.pathname === "/admin-panel" ||
          location.pathname === "/admin-panel/check-in") && (
          <span style={{ cursor: "pointer", float: "right" }}>
            <img
              src={Logout}
              alt=""
              width={"30px"}
              className="logout-button"
              onClick={() => {
                navigate("admin-login");
                localStorage.removeItem("token");
              }}
            />
          </span>
        )}
        <span>
          <a
            className="nav-link hover-underline mt-3"
            href="https://georgia.travel"
            target="_blank"
          >
            {t("visitGeorgia")}
          </a>
        </span>
      </div> */}
      <div></div>
      <nav
        className={`navbar navbar-expand-lg navbar-light sticky-top ${isScrolled ? "scrolled" : ""
          }`}
      >
        {/* <a
            className="ps-sm-2 ps-3 navbar-brand d-md-none d-block "
            style={{ color: "white" }}
            href="https://georgia.travel"
          >
            {t("visitGeorgia")}
          </a> */}

        <img
          src={currentLang !== "en" ? LogoSamWhiteGeo : LogoSamWhiteEng}
          onClick={() =>
            window.open(
              currentLang !== "en"
                ? "https://www.economy.ge/?lang=ge"
                : "https://www.economy.ge/?lang=en",
              "_blank"
            )
          }
          alt=""
          className="pic"
          style={{
            float: "left",
            paddingLeft: "10px",
            cursor: "pointer",
            width: "120px",
          }}
        />
        <img
          onClick={() => window.open("https://resorts.gov.ge/ ", "_blank")}
          src={currentLang !== "en" ? LogoGeoWhiteGeo : LogoGeoWhiteEng}
          alt=""
          className="pic"
          style={{
            float: "left",
            cursor: "pointer",
            marginLeft: "110px",
            position: "absolute",
            width: "140px",
          }}
        />
        <div className="collapse navbar-collapse ps-0" id="navbarNav">
          {!location.pathname.includes("admin") && (
            <ul className="navbar-nav mx-auto d-flex">
              <li className="nav-item my-auto active">
                <a
                  className="nav-link"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/");
                    window.scrollTo(0, 0);
                  }}
                >
                  {t("topbarHome")}
                </a>
              </li>

              {location.pathname === "/" && (
                <>
                  <li className="nav-item my-auto active">
                    <a
                      className="nav-link"
                      href="#conferance-2024"
                      onClick={(e) =>
                        handleScrollToSection(e, "conferance-2024")
                      }
                    >
                      {t("navbarConferance")}
                    </a>

                    <ul className="dropdown">
                      <li>
                        <div
                          onClick={(e) => handleScrollToSection(e, "program")}
                        >
                          {t("navbarProgram")}
                        </div>
                      </li>
                      <li>
                        <div
                          onClick={(e) => handleScrollToSection(e, "speakers")}
                        >
                          {t("speakers2")}
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item my-auto active">
                    <a className="nav-link"
                      style={{ cursor: "pointer" }} onClick={() => {
                        navigate("/logistic-and-travel");
                        window.scrollTo(0, 0);
                      }}>
                      {t("logisticAndTravel")}
                    </a>
                  </li>
                  <li className="nav-item my-auto active">
                    <a
                      className="nav-link"
                      href="#orginizor"
                      onClick={(e) => handleScrollToSection(e, "orginizor")}
                    >
                      {t("navbarOrg")}
                    </a>
                  </li>
                  <li className="nav-item my-auto active">
                    <a style={{ cursor: "pointer" }} className="nav-link">
                      {t("events")}
                    </a>

                    <ul
                      className="dropdown"
                      style={{
                        backgroundImage:
                          "linear-gradient(to right, rgb(72 72 85), rgb(86 84 91))",
                      }}
                    >
                      <li>
                        <div
                          onClick={() => {
                            navigate("/conference-2023");
                            window.scrollTo(0, 0);
                          }}
                        >
                          {t("firstInternConf")}
                        </div>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item my-auto active">
                    <a className="nav-link" href="#contact">
                      {t("navbarContact")}
                    </a>
                  </li>
                </>
              )}
            </ul>
          )}
          {location.pathname === "/conference-2023" && (
            <ul className="navbar-nav d-flex">
              {/* <li className="nav-item my-auto active">
                  <a
                    onClick={() => window.scrollTo(0, 0)}
                    style={{ cursor: "pointer" }}
                    className="nav-link"
                  >
                    1st International Conference 2023
                  </a>
                </li> */}
            </ul>
          )}
        </div>
        {location.pathname !== "/admin-panel" && (
          <img
            onClick={handleLangToggle}
            src={currentLang === "ka" ? EngFlag : GeoFlag}
            height={"45px"}
            className="float-end pe-xl-5 pe-sm-2 pe-3"
            style={{ cursor: "pointer" }}
            alt="Language Toggle"
          />
        )}
        {(location.pathname === "/admin-panel" ||
          location.pathname === "/admin-panel/check-in") && (
            <span style={{ cursor: "pointer", float: "right" }}>
              <img
                src={Logout}
                alt=""
                width={"30px"}
                className="logout-button"
                onClick={() => {
                  navigate("admin-login");
                  localStorage.removeItem("token");
                }}
              />
            </span>
          )}
      </nav>
    </>
  );
}

export default Navbar;
