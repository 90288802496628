import Speaker1 from "../assets/images/speaker1.jpg";
import Speaker2 from "../assets/images/Speakers-02.png";
import Speaker3 from "../assets/images/Speakers-03.png";
import Speaker5 from "../assets/images/Speakers-05.png";
import Speaker6 from "../assets/images/Speakers-04.png";

import hiruka from "../assets/images/სპიკერები-03.png";
import xudura from "../assets/images/სპიკერები-01.png";
import markf from "../assets/images/სპიკერები-02.png";

import ger from "../assets/images/ger.png";
import tam from "../assets/images/Photo - Tamar Gabunia-02.png";

import sergio from "../assets/images/სერგიო-28.png";

import GrayUser from "../assets/images/rrrrrrrr.png";

import Tevzadze from "../assets/images/tevzadze.png";
import tbc from "../assets/images/tbc.png";
import Student from "../assets/images/stud.png";

import SR from "../assets/images/rrrrrAAAA.png";

export const speakersArrEng = [
  {
    id: 0,
    name: "Tamar gabunia",
    position: `First Deputy Minister <br />
    Ministry of Internally<br /> Displaced Persons from the Occupied Territories, Labour, Health and
    Social Affairs of Georgia`,
    description: `Dr. Tamar Gabunia is the First Deputy Minister responsible for the health portfolio at the Ministry
    of Internally Displaced Persons from the Occupied Territories, Labour, Health and Social Affairs
    of Georgia. Dr. Gabunia assumed this role in February 2019. She oversees the operations of the
    Department of Health Care, the Regulation Agency for Medical and Pharmaceutical Activities as
    well as primary health care, public health and Global Fund supported programs. Additionally, she
    serves as a parliamentary secretary. Since September 2022, she has been assigned as the
    Acting Director General of the National Center for Disease Control and Public Health. In 2020,
    she was elected as a member of the Governance Committee of the Gavi, the Vaccine Alliance
    Board. She actively participates in developing and implementing governance policies and
    practices for the Gavi Board. <br />
    She has more than 20 years of working experience in the health sector in Georgia and over 10
    low-and-middle-income country settings, focusing on fostering health system strengthening and
    activities related to TB and MDR TB. Her technical expertise includes fields such as TB, Malaria,
    HIV, Primary Health Care, health financing, governance, health human resources, continuous
    medical education, healthcare quality improvement, and the elaboration and implementation
    support of guidelines. <br />
    In 2011-2016, as Chief of Party for the Georgia Tuberculosis Prevention Project/USAID, she has
    led a team of local and international experts and worked with local government officials to
    strengthen the national TB response. In 2017-2018, she worked as an international expert,
    contributing to TB management, pharmacovigilance and health system strengthening in various
    countries, including the Philippines, Cambodia, Ukraine, Turkmenistan, India, Vietnam, South
    Africa. From 2018 to February 2019, Dr Gabunia served as the technical team leader of the
    USAID TB Platforms for Sustainable Detection, Care and Treatment Project in the Philippines.
    Tamar Gabunia is a medical doctor from Tbilisi State Medical University, certified family medicine
    specialist with Masters in Public Health from the University of Georgia, United States.`,
    img: tam,
  },
  {
    id: 1,
    name: "Davit Tabidze",
    position: `Executive Director <br />
    LEPL Municipal Development Fund of Georgia (MDF)`,
    description: `Davit Tabidze has been working in various managerial positions in
    Georgia, as well as in foreign organizations, since 1999. <br />
    At the Municipal Development Fund, Davit Tabidze has held key
    positions such as adviser to the Executive Director, Head of
    Environmental Protection and Resettlement Unit, Deputy Executive
    Director and First Deputy Executive Director. Since 2021, he has been
    holding the position of Executive Director of the Fund.<br />
    The Municipal Development Fund of Georgia was established in 1997
    and has been operating under the Ministry of Regional Development
    and Infrastructure of Georgia (MRDI). MDF is cooperating with all the
    major investment banks and international financial institutions and is
    coordinated by the Supervisory Board approved by the Government of
    Georgia.`,
    img: tbc,
  },
  {
    id: 2,
    name: "Markos Danas",
    position: `Vice President <br />
    European Historic Thermal Towns Association (EHTTA)`,
    description: `Markos Danas was born in Thessaloniki in 1973. He is a manager with
      great experience in the private and public sectors, specializing in marketing strategies and business
      administration on Health tourism.
      Since 2013 he has been the Secretary - General of the Hellenic
      Association of Municipalities with Thermal Springs (HAMTS). He is also a
      member of the Committee for the Protection of Thermal Natural <br />
      Resources of the Greek Ministry of Tourism and Vice–President of the
      European Historic Thermal Towns Association (EHTTA). From 2007 to
      2009 he was a member of the Board of Directors of the municipal
      company of the thermal springs of Lagkadas and in 2010 he served as President. <br />
      Markos Danas holds the EuropeSpa Expert certification and he is a regular speaker at conferences and
      several events across Europe. He is a member
      of the judging committee of the Greek Tourism Awards. On behalf of
      HAMTS Markos Danas is a partner in Interreg and Erasmus + projects. He
      has published many articles and he is also a member of the organizing
      committees of many congresses, tourism exhibitions, and fam trips. <br /> 
      He graduated from the Technological Institute of Thessaloniki,
      School of business administration and Economics, department of
      Marketing and holds a Master in Business Administration from the
      Hellenic Management Association. He also holds a diploma in e-tourism
      (m-commerce and e-marketing in tourism) from the National and
      The Kapodistrian University of Athens. He is also qualified as a trainer for
      adults from EOPPEP and he is a member of the think tank for political and social issues
      \"INTERVENTION\"`,
    img: Speaker5,
  },
  {
    id: 3,
    name: "Maia Omiadze",
    position: "Head <br />LEPL Georgian National Tourism Administration",
    description: `Maia Omiadze assumed the role of Head of the Georgian National Tourism Administration in December
    2022. Before her current appointment, she amassed over 15 years of extensive experience in media,
    strategic planning, communication, and advertising, spanning both the public and private sectors. <br />
    Since 2010, Maia Omiadze actively participated in the development of marketing campaigns,
    formulation of development strategies, and collaborated with local and international institutions. She
    has held various managerial positions in key ministries of Georgia, including the Ministry of Economy
    and Sustainable Development, the Ministry of Culture and Monument Protection, the Ministry of Sports
    and Youth Affairs, as well as the Ministry of Labour, Health, and Social Affairs of Georgia. <br />
    Maia Omiadze earned her Bachelor&#39;s Degree in journalism from the Cinema and Theatre University of
Georgia and holds a Master's Degree in Communication from the Georgian University of Public Affairs. <br />
      She is fluent in English and Russian.`,
    img: Speaker1,
  },
  {
    id: 4,
    name: "Ioane Menabde",
    position: `Director <br />
    LEPL Spatial and Urban Development Agency`,
    description: `Ioane Menabde is the Head of LEPL Spatial and Urban Development Agency.
    He has a distinctive working experience in private and public sector within urban planning, property
    development, investment and feasibility analysis.
    He holds Master’s degree from IE University, where he studied real estate development and city-
    making. <br />During his earlier years he also graduated from Università Bocconi and CEU Business School, as a
    bachelor of a double degree program in international finance and business administration. 
    During his career, he has worked for various organizations in Georgia, such as Colliers International
    Georgia, TBC Bank, New City Development LLC, JSC Georgian Energy Development Fund and JSC
    Partnership Fund. <br />His goal is to contribute in improving livability and resiliency of the communities around the world by
    conscientious urban planning and real estate development.`,
    img: Speaker2,
  },
  {
    id: 5,
    name: "Jean-Marc FARINI",
    position: `Development Director <br />
    CDA French & International <br />
    President
    CDA Management`,
    description: `Grew up in the Chamonix Valley, in a family of hoteliers and mountain guides. While traveling
    worldwide, he is still mostly based at the foot of Mont-Blanc in the French Alps.
    Started his career as Director for Ernst &amp; Young in Paris and moved back to Chamonix, working for 9
    years at Compagnie du Mont-Blanc, the European largest ski operator, in various leading roles
    (management control, marketing &amp; development, operation, QSE policy).
    Then for more than 5 years, he was appointed  <b>General Manager of Rosa Khutor ski
    resort</b> which welcomed all Alpine races of the 2014 Olympics. <br />
    He assisted the Owner in all fields of ski resorts operation, technique and marketing, focusing on guest
    experience, safety and profitability. <br>
    He joined the International Dvpt Depart. of CDA in 2015 and led for CDA a diversity of projects
    worldwide in the fields of master planning, optimization of resorts, training, management advisory
    services, including major projects in China, Russia, Turkey, Japan, Kazakhstan…
    He took the lead of CDA Management in 2019 and now continue to share the vast experience of CDA
    group to the benefit of international, sizeable and balanced development projects.`,
    img: markf,
  },
  {
    id: 6,
    name: "Mikheil Khidureli",
    position: `Director <br />
    LEPL Enterprise Georgia`,
    description: `Mikheil is the CEO of “Enterprise Georgia” - a government economic development Agency operating
    under Ministry of Economy and Sustainable Development of Georgia. Prior to his appointment as the
    head of the Agency in early 2018, he served as a Founder/ Partner of SavvY Consulting and SavvY
    Education, which specializes in investor relationship, executive management, business development,
    coaching and leadership. <br />
    He has more than 7 years experience of middle and executive level management. He has worked on
    Multi-billion projects around the world as a project manager at SHARD Skyscraper construction project
    in London and New Doha Int. Airport Construction project in Qatar. Furthermore, Mikheil has worked as
    a Director of Business Development in large infrastructure company Saunders Group and as a Deputy
    CEO of a successful giant US start up company ,,Driveshift". He has also been a head of Business
    development department at United Airports Georgia.`,
    img: xudura,
  },
  {
    id: 7,
    name: "Hiroyuki Mori",
    position: `Resident Representative <br />
    JICA Georgia Office`,
    description: `Since joining JICA in 1996, he has been involved in regional revitalization and
    post-conflict reconstruction projects mainly in the Middle East and the
    Balkans, including Syria, Iraq, and Sudan. He is interested in how to combine
    assistance to developing countries with Japan knowledge and experience in
    regional development. He has visited about 50 countries for development
    studies and economic and social surveys. <br /> <b>
    Conducted workshops on tourism development for regional revitalization by
    visiting various resort development projects and local governments in Japan.
    He also actively supports young people to start their own businesses and
    make I-turns and U-turns to rural areas. </b><br />
    He holds a B.A. in History of Education from Kyoto University and an M.A. in
    Development Administration`,
    img: hiruka,
  },
  {
    id: 8,
    name: "Florian Van der Bellen",
    position: `Director and Global Head <br />
    Destination Development <br />
    PKF hospitality group`,
    description: `Florian Van der Bellen joined PKF hospitality group in 2023. Based in Romania, he is responsible for destination development
      worldwide, such as sustainable tourism strategies and development plans, destination management, branding and marketing,
      and accessible tourism. <br />
      Florian holds a diploma from the College of Tourism, Innsbruck (Austria), and an Executive MBA from the University of
      Economics, Vienna (Austria). He has more than 30 years of practical experience in international hotel and tourism
      management and consulting in countries like Austria, Germany, Switzerland, Romania, Moldova, Armenia, Georgia,
      Azerbaijan, USA and Australia.<br />
      As Managing Director of the Destination Management Organization
      „Tiroler Oberland“ in Tyrol, Austria, Florian was responsible for the strategic positioning of the destination, branding and
      marketing. In addition, his focus on accessible tourism brought numerous national and international awards for the destination,
      such as the EDEN Award (European Destination of Excellence), the Alps Award (Top 5 Alpine Destination), and second place at
      the World Responsible Tourism Awards.<br />
      This practical experience helped Florian enormously for various DMO projects in Georgia, Romania, Moldova, Bosnia and
      Serbia.<br />
      Florian has successfully founded and managed tourism consulting companies in Austria, Georgia and Romania. His specialties
      are to make complex issues easily understandable and to produce practical, customer-oriented results.
      Florian is a member of the Advisory Board of GIPA (Georgian Institute
      of Public Affairs) and a sought after speaker at international tourism conferences.`,
    img: Speaker3,
  },
  {
    id: 9,
    name: "Natia Tevzadze Ph.D in Social Science",
    position: `CEO <br />
    Association of Physical Medicine, Balneology and Resortology`,
    description: `Natia Tevzadze has been the executive director of the Association of
    Physical Medicine, Balneology and Resortology of Georgia since July
    2023. <br />
    In 2009, she founded the consulting and marketing company "Demo
    Gogia Group", which serves international organizations and embassies
    and is a company providing event and logistics services for the
    European Union in Georgia. <br />
    
    Since 1994, Natia has been a member of the New York Academy of
    Sciences, and since 1996, a member of the American Psychological
    Association. <br />
    Natia Tevzadze is the author of the work "National and State
    Consciousness" (1992, "Pergamon Press" publishing house, United
    Kingdom). She is actively involved in public life and the formation of the
    civil sector, as well as in the development of youth, media, and culture
    at the Caucasian Institute for Peace, Democracy, and Development and
    the International Youth Foundation of Georgia.
    Natia Tevzadze actively conducts trainings in the management of non-
    formal education events for civil society. <br />
    In 2018-2020, Natia Tevzadze implemented 23 projects in Svaneti with
    the involvement of the local government and community, taking into
    account the social policy of the energy infrastructure construction
    company and international financial institutions (EBRD, ADB, EIB) in
    Georgia. She graduated from the Faculty of Psychology at Tbilisi State
    University.`,
    img: Tevzadze,
  },
  // {
  //   id: 10,
  //   name: "Representative of Portugal (TBC)",
  //   position: `Presentation of the Project REVIVE (Portugal) TBC`,
  //   description: ``,
  //   img: GrayUser,
  // },
  {
    id: 11,
    name: "M.D.Ladislav Spisak",
    position: `Chairman <br />
    Council of the Karlovy-Vary Institute of Balneology`,
    description: `MUDr. Ladislav Špišák, CSc after graduation at the Faculty of Medicine, Charles University in
      Pilsen and successful completion of the attestation from internal medicine since 1977 has
      been working in Karlovy Vary spa, since 1983 as head physician and director of the
      Sanatorium Astoria, the Sanatorium Bristol and since 2012 as the medical director of the
      Savoy Westend spa clinic in Karlovy Vary. Since 2019 he has been a researcher and chairman
      of the Council of the Institute of Spa and Balneology v.v.i. in Karlovy Vary<br />
      In 1980 he attended an attestation of balneology, physiatry and therapeutic rehabilitation and
      in 1985 defended the title of candidate of medical sciences in the field of internal medicine
      specializing in diabetology. Since the 1980s, he has been working as an external professional
      assistant to the Faculty of Medicine, Charles University in Pilsen. He lectures in the field of
      rehabilitation, physiatry, and balneology for students of the Faculty of Medicine in Pilsen, the
      3rd Faculty of Medicine of Charles University in Prague and the University of West Bohemia in
      Pilsen.<br />
      He is a member of the Czech Medical Society, Gastroenterological and Diabetology Society,
      Internal Medicine.
      He is a member of the European Association for predictive, preventive and personalized
      medicine.<br />
      In 2007 he received the Prize of the Association of Medical Spa in Karlovy Vary for many years
      of activity in balneology and in 2019 the Karlovy Vary Award for many years of work in
      balneology.<br />
      In 2010 was the main editor of the publication Clinical balneology.`,
    img: Speaker6,
  },
  {
    id: 12,
    name: "Dr. Gerard Andre",
    position: `Managing Director <br />
    Tempo Hospitality Consulting SA`,
    description: `Expert in the fields of Wellness and Leisure, Gérard brings with him an in-depth
    expertise in conceiving, launching and implementing health, water and leisure,
    tourism-related projects. He is in charge of designing, programming and performing
    forward-looking operational and financial analysis, as well as establishing
    management models.<br />
      Gérard has over 30 years of experience in managing leading thermal and recreational baths,
      tourism resorts in Andorra, Belgium, France and Switzerland. He has been involved in all
      phases of the development of these resorts, supervising their design and construction,
      coordinating operators and managing their launches. These facilities welcome over 1.5
      million visitors every year and all are profitable.<br />
      Gérard is providing consulting services to various public and private projects and operational
      management in several countries, mainly in Europe and in North Africa; as an expert in
      development programs between the E.E.C. and other countries.<br />
      He is the author of several reports about tourism, thermal and wellness projects or programs
      for several ministries and governmental agencies (Andorra, Algeria, France, Morocco,
      Tunisia,…).<br />
      Gérard is Swiss and French. He holds a PhD in Law along with the French post-doctorate
      certification Docteur d’Etat in Political Science.`,
    img: ger,
  },
  {
    id: 13,
    name: "Sergio Gianesini MD, PhD, FACS",
    position: `Associate Professor <br />
    University of Ferrara, Italy <br />
    University of Health Sciences in Bethesda, USA`,
    description: `Sergio Gianesini is a surgeon of the University of Ferrara, Italy, where he holds an associate
    professorship Italian habilitation in cardiovascular surgery and where he is dedicated to Vein &amp;
    Lymphatic basic science, clinical research and practice. He is part of the permanent teaching body of the
    School of Medicine, as well as of the Vascular Surgery Residency program, of the courses of
    Physiotherapy and of the School of Dentistry. He is an adjunct associate professor in Surgery at the
    Uniformed Services University of Health Sciences in Bethesda, USA. He has a PhD in Vascular System
    Molecular Patho-physiology and he is part of the teaching body of the Advanced Therapies &amp;
    Experimental Pharmacology PhD course at the University of Ferrara. <br />
    Inside the University of Ferrara PhD programs he is involved in the internationalization process,
    facilitating interaction among different countries universities. He is invited professor of the Angiology II
    level Master of the University of Padova (Italy).<br />
    He is invited professor of the Albania University as well. He serves as editor in chief of Vascular Insights
    journal and as associate Editor of Phlebology Journal and of International Angiology Journal. He is in the
    editorial boards of Journal of Vascular Surgery venous &amp; lymphatic disorders, of the Chinese Medical
    Journal, of the Surgical Sciences Journal and of the Turkish Journal of Vascular Surgery. He has published
    more than 80 scientific papers on vein-lymphatics topics, 3 textbooks and he has delivered more than
    380 lectures around the world.<br />
    He is a founding member of the venous-lymphatic World International Network (v-WIN) foundation,
    with which he developed projects leading to an official recognition of the United Nations
    Communications Coordination Committee and to an institutional representation during the 2021-2022
    Universal Expo World Fair.<br />
    From 2018 to 2022 he has served as UIP vice-president, President-Elect from 2022 to 2023 and serves
    now as the UIP President from 2023 to 2027.<br />
    He is the founding member of the Science Per Aquam University of Ferrara project, dedicated to bring
    evidence-based science in support of the appropriate SPA treatments, with a particular focus on vein-
    lymphatic drainage, but with a multi-specialty involvement.<br />
    He&#39;s currently serving also as co-Director of the Global Care Hospital Vein-Lympahtic Clinic in Abu Dhabi
    (UAE).`,
    img: sergio,
  },
  {
    id: 14,
    name: "Nino Chaduneli",
    position: `Student <br />
    Ilia State Universiry`,
    description: `Nino Chaduneli is 20 years old. In 2020, she entered Ilia State University, Faculty of
    Business Technology and Education, majoring in tourism. She is passionate about
    traveling, she has been visiting different parts of Georgia every month for three
    years, she has her own travel agency and offers tours to the public every month.
    Nino actively participates in the development of the family business.
    Born and raised in Surami, Nino wants to contribute to the development of Surami
    Resort. Nino has a great desire to continue her studies abroad and is already
    preparing for it.`,
    img: Student,
  },
  {
    id: 15,
    name: "Prof. Tamar Chilingarashvili",
    position: `Rheumatologist, Balneo Therapist <br /> Tbilisi Balneological Spa Resort`,
    description: ``,
    img: SR,
  },
];
export const speakersArrGeo = [
  {
    id: 0,
    name: "თამარ გაბუნია",
    position: `მინისტრის პირველი მოადგილე <br /> საქართველოს ოკუპირებული ტერიტორიებიდან იძულებით გადაადგილებულ
    პირთა, შრომის, ჯანმრთელობისა და სოციალური დაცვის სამინისტრო`,
    description: `თამარ გაბუნია საქართველოს ოკუპირებული ტერიტორიებიდან დევნილთა, შრომის,
    ჯანმრთელობისა და სოციალური დაცვის მინისტრის პირველი მოადგილის პოზიციას 2019
    წლის თებერვლიდან იკავებს და ჯანდაცვის მიმართულებას ხელმძღვანელობს. იგი
    ზედამხედველობს ჯანმრთელობის დაცვის პოლიტიკის დეპარტამენტის და სსიპ
    სამედიცინო და ფარმაცევტული საქმიანობის რეგულირების სააგენტოს საქმიანობას, ასევე
    პირველადი ჯანდაცვის, საზოგადოებრივი ჯანდაცვისა და გლობალური ფონდის მიერ
    მხარდაჭერილ პროგრამებს და აკისრია საპარლამენტო მდივნის ფუნქცია. 2022 წლის
    სექტემბრიდან ასრულებს სსიპ ლ. საყვარელიძის სახელობის დაავადებათა კონტროლისა
    და საზოგადოებრივი ჯანმრთელობის ეროვნული ცენტრის დირექტორის მოვალეობას.
    2020 წელს არჩეულ იქნა იმუნიზაციისა და ვაქცინების გლობალური ალიანსის (GAVI)
    საბჭოს მმართველობითი კომიტეტის წევრად. <br />
    თამარ გაბუნიას საზოგადოებრივ ჯანდაცვისა და ჯანდაცვის სისტემების გაძლიერების
    სფეროში მუშაობის 20 წელზე მეტი გამოცდილება გააჩნია, როგორც საქართველოში, ასევე
    უცხოეთის 10-ზე მეტ ქვეყანაში. მისი ტექნიკური ექსპერტიზის სფეროებია
    ტუბერკულოზისა და აივ-შიდსის კონტროლი, მალარიის პროგრამების დაგეგმვა,
    პირველადი ჯანდაცვა, ჯანდაცვის სფეროში ხარისხის გაუმჯობესება, ჯანდაცვის
    დაფინანსება, ადამიანური რესურსი ჯანდაცვისთვის და უწყვეტი პროფესიული
    განვითარება, გაიდლაინების შემუშავება და მათი დანერგვის ხელშეწყობა.
    2011-2016 წლებში ხელმძღვანელობდა აშშ საერთაშორისო განვითარების სააგენტოს
    ტუბერკულოზის პრევენციის პროექტს. 2017-2018 წლებში მუშაობდა როგორც
    საერთაშორისო ექსპერტი სხვადასხვა ქვეყანაში (მ.შ. ფილიპინები, კამბოჯა, უკრაინა,
    თურქმენეთი, ინდოეთი, ვიეტნამი, სამხრეთ აფრიკა) ტუბერკულოზის,
    <br />
    ფარმაკოზედამხედველობისა და ჯანდაცვის სისტემის გაძლიერების მიმართულებით.
    2018 წლიდან 2019 წლის თებერვლამდე ხელმძღვანელობდა აშშ საერთაშორისო
    განვითარების სააგენტოს პროექტს „პლატფორმა ტუბერკულოზის გამოვლენის,
    მართვისა და პრევენციისთვის“ მანილაში, ფილიპინები.
    თამარ გაბუნიას აქვს სამედიცინო განათლება, არის საოჯახო მედიცინის
    სერტიფიცირებული სპეციალისტი და ფლობს საზოგადოებრივი ჯანდაცვის მაგისტრის
    ხარისხს (ჯორჯიას უნივერსიტეტი, აშშ).`,
    img: tam,
  },
  {
    id: 1,
    name: "დავით ტაბიძე",
    position: `აღმასრულებელი დირექტორი <br />
    სსიპ საქართველოს მუნიციპალური განვითარების ფონდი`,
    description: `დავით ტაბიძე 1999 წლიდან მუშაობს სხვადასხვა
    მენეჯერულ პოზიციაზე როგორც ქართულ, ასევე უცხოურ
    ორგანიზაციებში. <br />
    მუნიციპალური განვითარების ფონდში დავით ტაბიძე
    იკავებდა ისეთ წამყვან თანამდებობებს, როგორიცაა
    აღმასრულებელი დირექტორის მრჩეველი, გარემოს დაცვისა
    და განსახლების განყოფილების უფროსი, აღმასრულებელი
    დირექტორის მოადგილე, ასევე აღმასრულებელი <br />
    
    დირექტორის პირველი მოადგილე. 2021 წლიდან ის იკავებს
    ფონდის აღმასრულებელი დირექტორის თანამდებობას.
    საქართველოს მუნიციპალური განვითარების ფონდი 1997
    წელს დაარსდა და ფუნქციონირებს საქართველოს
    რეგიონული განვითარებისა და ინფრასტრუქტურის
    სამინისტროს დაქვემდებარებაში. ფონდი თანამშრომლობს
    ყველა მსხვილ საინვესტიციო ბანკთან და საერთაშორისო
    ფინანსურ ინსტიტუტთან, ასევე, კოორდინაციას უწევს
    საქართველოს მთავრობის მიერ დამტკიცებული
    სამეთვალყურეო საბჭოს.`,
    img: tbc,
  },
  {
    id: 2,
    name: "მარკოს დანასი",
    position: `ვიცე-პრეზიდენტი <br />
    ევროპის ისტორიული თერმული ქალაქების ასოციაცია (EHTTA)`,
    description: `მარკოს დანასი 1973 წელს სალონიკში დაიბადა. იგი
    სპეციალიზირებულია ჯანმრთელობის ტურიზმის მიმართულებით
    მარკეტინგული სტრატეგიებისა და ბიზნესის ადმინისტრირების
    სფეროში და აქვს კერძო და საჯარო სექტორში მენეჯერად მუშაობის
    დიდი გამოცდილება. <br />2013 წლიდან არის საბერძნეთის თერმული წყლების
    მუნიციპალიტეტების ასოციაციის გენერალური მდივანი (HAMTS). ის
    ასევე არის საბერძნეთის ტურიზმის სამინისტროს თერმული ბუნებრივი
    რესურსების დაცვის კომიტეტის წევრი და ევროპის ისტორიული
    თერმული ქალაქების ასოციაციის (EHTTA) ვიცე-პრეზიდენტი. <br />2007-2009 წლებში იყო ლაგკადასის თერმული წყაროების
    მუნიციპალური კომპანიის დირექტორთა საბჭოს წევრი. 2010 წელს
    ამავე კომპანიის პრეზიდენტის თანამდებობას იკავებდა. <br /> ამავე კომპანიის პრეზიდენტის თანამდებობას იკავებდა.
    მარკოს დანასი EuropeSpa Expert-ის სერტიფიკატს ფლობს და ევროპის
    სხვადასხვა კონფერენციისა და ღონისძიების მომხსენებელია.
    დანასი არის საბერძნეთის ტურიზმის დაჯილდოების ჟიურის
    კომიტეტის წევრი. საბერძნეთის თერმული წყლების
    მუნიციპალიტეტების ასოციაციის სახელით არის Interreg და Erasmus+
    პროექტების პარტნიორი. მას გამოქვეყნებული აქვს მრავალი სტატია და
    არის სხვადასხვა კონგრესის, ტურისტული გამოფენისა და ფერმერული
    ტურიზმის საორგანიზაციო კომიტეტების წევრი. <br /> მარკოსმა დაამთავრა სალონიკის ტექნოლოგიური ინსტიტუტი
    ბიზნესის ადმინისტრირებისა და ეკონომიკის სკოლის მარკეტინგის
    მიმართულებით და ფლობს საბერძნეთის მენეჯმენტის ასოციაციის
    ბიზნესის ადმინისტრირების მაგისტრის ხარისხს. მას ასევე აქვს
    კაპოდისტრიის სახელობის ათენის ეროვნული უნივერსიტეტის
    დიპლომი ციფრული ტურიზმის მიმართულებით (m-commerce, e-
    marketing). იგი არის მოზარდების ტრენერი
    
    EOPPEP-დან და აგრეთვე სოციალური საკითხების პოლიტიკური კვლევითი ცენტრის
    \"INTERVENTION\"-ის წევრი.`,
    img: Speaker5,
  },
  {
    id: 3,
    name: "მაია ომიაძე",
    position:
      "ხელმძღვანელი <br /> სსიპ საქართველოს ტურიზმის ეროვნული ადმინისტრაცია",
    description: `მაია ომიაძემ საქართველოს ტურიზმის ეროვნული ადმინისტრაციის ხელმძღვანელის
    თანამდებობა 2022 წლის დეკემბერში დაიკავა. მას 15 წლიანი სამუშაო გამოცდილება
    აქვს მედიის, სტრატეგიული დაგეგმარებისა და კომუნიკაციის სფეროში, რომელიც
    მოიცავს როგორც საჯარო, ისე კერძო სექტორს. <br /> 2010 წლიდან იგი აქტიურად მონაწილეობდა მარკეტინგული კამპანიებისა და
    სტრატეგიების შემუშავებაში, თანამშრომლობდა როგორც ადგილობრივ, ისე
    საერთაშორისო ორგანიზაციებთან. მას ეკავა სხვადასხვა მენეჯერული თანამდებობა
    საქართველოს ეკონომიკისა და მდგრადი განვითარების სამინისტროში, კულტურისა და
    
    ძეგლთა დაცვის სამინისტროში, სპორტისა და ახალგაზრდობის საქმეთა სამინისტროსა
    და ოკუპირებული ტერიტორიებიდან დევნილთა, შრომის, ჯანმრთელობისა და
    სოციალური დაცვის სამინისტროში. <br /> მაია ომიაძეს აქვს საქართველოს კინოსა და თეატრის უნივერსიტეტის ჟურნალისტიკის
    ფაკულტეტის ბაკალავრის ხარისხი და საქართველოს საზოგადოებრივ საქმეთა
    ინსტიტუტის მაგისტრის ხარისხი კომუნიკაციაში. <br /> თავისუფლად ფლობს ინგლისურ და რუსულ ენებს.`,
    img: Speaker1,
  },
  {
    id: 4,
    name: "იოანე მენაბდე",
    position: `დირექტორი <br />
    სსიპ სივრცითი და ქალაქთმშენებლობითი განვითარების სააგენტოს`,
    description: `იოანე მენაბდე არის სსიპ სივრცითი და ქალაქთმშენებლობითი განვითარების სააგენტოს
    ხელმძღვანელი.
    მას აქვს გამორჩეული სამუშაო გამოცდილება კერძო და საჯარო სექტორში, რაც მოიცავს
    ურბანულ დაგეგმარებას, ქონების მართვას, ინვესტიციებისა და ტექნიკურ-
    ეკონომიკური მიზანშეწონილობის ანალიზს. <br />მას აქვს IE უნივერსიტეტის მაგისტრის ხარისხი, სადაც სწავლობდა უძრავი ქონების
    განვითარებასა და ქალაქთმშენებლობას.
    ადრეულ წლებში, მან დაამთავრა Università Bocconi და CEU Business School, აქვს ბაკალავრის
    ხარისხი ორმაგი ხარისხის პროგრამის ფარგლებში საერთაშორისო ფინანსებსა და
    ბიზნესის ადმინისტრირებაში.<br />მისი სამუშაო გამოცდილება საქართველოში უკავშირდება ისეთ ორგანიზაციებს
    როგორიცაა: Colliers International Georgia, თიბისი ბანკი, New City Development LLC, სს
    საქართველოს ენერგეტიკის განვითარების ფონდი და სს საპარტნიორო ფონდი.
    იოანეს მიზანია წვლილი შეიტანოს მოსახლეობის სიცოცხლისუნარიანობისა და
    მდგრადობის გაუმჯობესებაში კეთილსინდისიერი ურბანული დაგეგმარებისა და
    უძრავი ქონების განვითარების საშუალებით.`,
    img: Speaker2,
  },
  {
    id: 5,
    name: "ჟან-მარკ ფარინი",
    position: `განვითარების დირექტორი <br />
    CDA French & International <br />
    პრეზიდენტი
    CDA Management`,
    description: `ჟან მარკ ფარინი საფრანგეთში შამონის ხეობაში, სასტუმროების მფლობელებისა და
    სამთო გიდების ოჯახში გაიზარდა. მიუხედავად იმისა, რომ იგი მსოფლიოს გარშემო
    მოგზაურობს ჟან მარკი საფრანგეთის ალპებში მონბლანის მთის ქვეშ ცხოვრებას ანიჭებს
    უპირატესობას. <br />მისი კარიერა პარიზში Ernst &amp; Young-ის დირექტორობით იწყება და ევროპის უმსხვილეს
    სათხილამურო კომპანია Compagnie du Mont-Blanc-ში მუშაობით გრძელდება, სადაც 9 წლის
    განმავლობაში სხვადასხვა წამყვან პოზიციას იკავებდა სათხილამურო კურორტების
    მართვის, მარკეტინგის, ოპერირებისა და ხარისხის მართვის პოლიტიკის
    მიმართულებით. <br />მომდევნო 5 წლის განმავლობაში იგი მუშაობდა სამთო სათხილამურო კურორტ როზა
    ხუტორის გენერალური მენეჯერის თანამდებობაზე, რომელმაც 2014 წლის ზამთრის
    ოლიმპიადას უმასპინძლა.
    ჟან მარკი 2015 წელს Compagnie Des Alpes-ის საერთაშორისო განვითარების დეპარტამენტს
    შეუერთდა და ჩინეთში, თურქეთში, იაპონიაში, ყაზახეთსა და რუსეთში კომპლექსურ
    პროექტებს ხელმძღვანელობდა. მისი საქმიანობა მოიცავდა პროექტების გენერალურ
    დაგეგმვას, კურორტების ოპტიმიზაციას, ტრენინგებსა და მენეჯმენტის
    საკონსულტაციო მომსახურებას. <br />2019 წლიდან მართავს Compagnie Des Alpes და აქტიურად აგრძელებს ჯგუფის დიდი
    გამოცდილების გაზიარებას საერთაშორისო, მასშტაბური და მდგრადი განვითარების
    პროექტების სასარგებლოდ.`,
    img: markf,
  },
  {
    id: 6,
    name: "მიხეილ ხიდურელი",
    position: `დირექტორი <br />
    სსიპ აწარმოე საქართველოში`,
    description: `მიხეილ ხიდურელი 2018 წლის 14 მაისიდან სააგენტოს „აწარმოე საქართველოში“
    დირექტორია. იგი იყო „SavvY Consulting”-ისა და „SavvY Education”-ის დამფუძნებელი და
    პარტნიორი. მას ინვესტორებთან ურთიერთობის, აღმასრულებელ პოზიციაზე მუშაობის,
    ბიზნესის განვითარების, ქოუჩინგისა და ლიდერობის გამოცდილება აქვს.
    იგი იკავებდა „SHARD”-ის ცათამბჯენის კონსტრუქციის, ლონდონისა და დოჰას ახალი
    საერთაშორისო აეროპორტების მშენებლობის პროექტების მენეჯერის თანამდებობას.
    მიხეილი დირექტორის პოზიციაზე მუშაობდა მსხვილმასშტაბიან ინფრასტრუქტურულ
    კომპანია Saunders Group-ში, ხოლო აღმასრულებელი დირექტორის პოზიციაზე ამერიკის <br />
    
    გიგანტურ სტარტაპ კომპანია Driveshift-ში. იგი, ასევე იყო ბიზნესის განვითარების
    დეპარტამენტის უფროსი საქართველოს აეროპორტების გაერთიანებაში.`,
    img: xudura,
  },
  {
    id: 7,
    name: "ჰიროიუკი მორი",
    position: `მუდმივი წარმომადგენელი
    იაპონიის საერთაშორისო თანამშრომლობის სააგენტო
    JICA საქართველოს ოფისი`,
    description: `1996 წელს JICA-ში მუშაობის დაწყების შემდეგ, ის ახორციელებს საქმიანობას
    რეგიონული რევიტალიზაციის და პოსტ-კონფლიქტური რეკონსტრუქციის
    პროექტებში, ძირითადად ახლო აღმოსავლეთის და ბალკანეთის ქვეყნებში,
    რომელთა შორისაც არის სირია, ერაყი და სუდანი. <br /> <b>
    მისი ინტერესის სფეროს წარმოადგენს იაპონიის ცოდნისა და გამოცდილების გაზიარება
    განვითარებადი ქვეყნების რეგიონულ განვითარებაში. ეკონომიკური და სოციალური
    კვლევებისა და განვითარების შესწავლის მიზნით იგი 50 ქვეყანას ეწვია. </b><br />
    განხორციელებული აქვს სემინარები ტურიზმის განვითარების და რეგიონული
    რევიტალიზაციის მიზნებისთვის, რაც მოიცავდა იაპონიაში არსებული კურორტების
    განვითარების პროექტების და რეგიონული მუნიციპალიტეტების საქმიანობის
    გაცნობას. ის ასევე აქტიურად ხელს უწყობს ახალგაზრდებს საკუთარი ბიზნესის
    განვითარებაში აგრარულ და პერიფერიულ რეგიონებში.
    ჰიროიუკი მორის მიღებული აქვს ბაკალავრის წოდება, კიოტოს უნივერსიტეტში
    და მაგისტრის წოდება, მანჩესტერის უნივერსიტეტში. ის დაიბადა 1960 წელს.`,
    img: hiruka,
  },
  {
    id: 8,
    name: "ფლორიან ვან დერ ბელენი",
    position: `დირექტორი <br />
    PKF hospitality group`,
    description: `ფლორიან ვან დერ ბელენი შეუერთდა PKF სტუმარმასპინძლობის ჯგუფს 2023 წელს.
    მცხოვრები რუმინეთში, ის პასუხისმგებელია დანიშნულების ადგილის განვითარებაზე
    მთელ მსოფლიოში, როგორიცაა მდგრადი ტურიზმის სტრატეგიები და განვითარების
    გეგმები, დანიშნულების ადგილის მართვის, ბრენდინგი და მარკეტინგი და
    ხელმისაწვდომი ტურიზმი. <br />
    
    ფლორიანი ფლობს ინსბრუკის (ავსტრია) ტურიზმის კოლეჯის დიპლომს და MBA-ს
    ეკონომიკის უნივერსიტეტიდან, ვენა (ავსტრია). მას აქვს 30 წელზე მეტი პრაქტიკული
    გამოცდილება საერთაშორისო სასტუმროებისა და ტურიზმის მენეჯმენტში და
    კონსულტანტია ისეთ ქვეყნებში, როგორიცაა ავსტრია, გერმანია, შვეიცარია, რუმინეთი,
    მოლდოვა, სომხეთი, საქართველო, აზერბაიჯანი, აშშ და ავსტრალია.<br />
    
    ტიროლში, ავსტრია, „Tiroler Oberland“ დანიშნულების ადგილის მართვის ორგანიზაციის
    მმართველ დირექტორად ყოფნისას ფლორიანი პასუხისმგებელი იყო დანიშნულების
    ადგილების სტრატეგიულ პოზიციონირებაზე, ბრენდირებასა და მარკეტინგზე. გარდა ამისა,
    ის ფოკუსირებული იყო ხელმისაწვდომ ტურიზმზე მიიღო მრავალი ეროვნული და
    საერთაშორისო ჯილდო დანიშნულების ადგილებისთვის, როგორიცაა EDEN Award
    (European Destination of Excellence), Alps Award (Top 5 Alpine Destination) და მეორე ადგილი
    World Responsible Tourism Awards-ზე.<br />
    ეს პრაქტიკული გამოცდილება ძალიან დაეხმარა ფლორიანს სხვადასხვა დანიშნულების
    ადგილის (DMO) პროექტებში საქართველოში, რუმინეთში, მოლდოვაში, ბოსნიასა და
    სერბეთში.<br />
    ფლორიანმა წარმატებით დააარსა და მართავს ტურისტული საკონსულტაციო კომპანიები
    ავსტრიაში, საქართველოსა და რუმინეთში. მას შესწევს უნარი კომპლექსური საკითხების
    ადვილად გასაგები, პრაქტიკული, მომხმარებელზე ორიენტირებული შედეგების მიღება.
    ფლორიანი არის GIPA-ს (საქართველოს საზოგადოებრივ საქმეთა ინსტიტუტის) მრჩეველთა
    საბჭოს წევრი და მოთხოვნადი მომხსენებელი საერთაშორისო ტურისტულ
    კონფერენციებზე.`,
    img: Speaker3,
  },
  {
    id: 9,
    name: "ნათია თევზაძე",
    position: `აღმასრულებელი დირექტორი <br />
    ფიზიკური მედიცინის, ბალნეოლოგიის და კურორტოლოგიის ასოციაცია`,
    description: `ნათია თევზაძე საქართველოს ფიზიკური მედიცინის,
    ბალნეოლოგიისა და კურორტოლოგიის ასოციაციის
    
    აღმასრულებელი დირექტორის თანამდებობას 2023 წლის
    ივლისიდან იკავებს. <br />
    2009 წელს დააფუძნა საკონსულტაციო და მარკეტინგული
    კომპანია „დემო გოგია გრუპი“, რომელიც საერთაშორისო
    ორგანიზაციებს და საელჩოებს ემსახურება და არის
    საქართველოში ევროკავშირისთვის ღონისძიებებისა და
    ლოჯისტიკური სერვისების მიმწოდებელი კომპანია.
    1994 წლიდან ნათია ნიუ იორკის მეცნიერებათა აკადემიის
    და 1996 წლიდან აშშ-ს ფსიქოლოგთა საერთაშორისო
    ასოციაციის წევრია. <br />
    ნათია თევზაძე არის ავტორი ნაშრომისა „ეროვნული და
    სახელმწიფოებრივი ცნობიერება” (1992წ, “პერგამონ პრესის”
    გამომცემლობა, დიდი ბრიტანეთი). ის აქტიურად არის
    ჩართული საზოგადოებრივ ცხოვრებაში და სამოქალაქო
    სექტორის ფორმირებაში, მშვიდობის, დემოკრატიის და
    განვითარების კავკასიურ ინსტიტუტში და საქართველოს
    ახალგაზრდობის საერთაშორისო ფონდში ახალგაზრდობის,
    მედიისა და კულტურის განვითარებაში.
    ნათია თევზაძე აქტიურად ატარებს ტრენინგებს სამოქალაქო
    საზოგადოებისთვის არაფორმალური განათლების
    ღონისძიებების მენეჯმენტში. <br />
    2018-2020 წლებში ნათია თევზაძემ საქართველოში
    ენერგეტიკული ინფრასტრუქტურის მშენებელი კომპანიის
    და საერთაშორის ფინანსური ინსტიტუტების (EBRD, ADB,EIB)
    სოციალური პოლიტიკის გათვალისწინებით სვანეთში
    ადგილობრივი ხელისუფლების და თემის ჩართულობით 23
    <br />
    პროექტი განახორციელა. დამთავრებული აქვს თბილისის
    სახელმწიფო უნივერსიტეტის ფსიქოლოგიის ფაკულტეტი.`,
    img: Tevzadze,
  },
  // {
  //   id: 10,
  //   name: "პორტუგალიის წარმომადგენელი (TBC)",
  //   position: `პროექტ REVIVE-ის პრეზენტაცია (პორტუგალია) TBC`,
  //   description: ``,
  //   img: GrayUser,
  // },
  {
    id: 11,
    name: "მედიცინის დოქტორი ლადისლავ შპიშაკი",
    position: `საბჭოს თავმჯდომარე <br />
    კარლოვი ვარის ბალნეოლოგიის ინსტიტუტი`,
    description: `მედიცინის დოქტორი ლადისლავ შპიშაკი, CSc, ჩარლზის უნივერსიტეტის პილსენის მედიცინის
    ფაკულტეტის დამთავრების და შინაგანი მედიცინის ატესტაციის წარმატებით
    დასრულების შემდეგ 1977 წლიდან მუშაობს კარლოვი ვარის სპა-ში, 1983 წლიდან,
    როგორც მთავარი ექიმი და სანატორიუმების “ასტორიას” და “ბრისტოლის” დირექტორი.
    ხოლო 2012 წლიდან კარლოვი ვარის სპა კლინიკის Savoy Westend-ის სამედიცინო
    დირექტორად. 2019 წლიდან არის კურორტო-ბალნეოლოგიის ინსტიტუტის მკვლევარი
    და საბჭოს თავმჯდომარე ვ.ვ.ი. კარლოვი ვარში <br />
    1980 წელს გაიარა ბალნეოლოგიის, ფიზიატრია და თერაპიული რეაბილიტაციის
    ატესტაცია და 1985 წელს დაიცვა მედიცინის მეცნიერებათა კანდიდატის წოდება
    შინაგანი მედიცინის დარგში დიაბეტის სპეციალობით. 1980-იანი წლებიდან ის მუშაობს
    პილსენის ჩარლზის უნივერსიტეტის სამედიცინო ფაკულტეტის მოწვეულ
    პროფესიონალ მრჩევლად. ის კითხულობს ლექციებს რეაბილიტაციის, ფიზიატრიასა და
    ბალნეოლოგიის სფეროში პილსენის მედიცინის ფაკულტეტის, პრაღის ჩარლზის

    უნივერსიტეტის მედიცინის მე-3 ფაკულტეტის და პილსენის დასავლეთ ბოჰემიის
    უნივერსიტეტის სტუდენტებისთვის.<br />
    ის არის ჩეხეთის სამედიცინო საზოგადოების, გასტროენტეროლოგიური და
    დიაბეტოლოგიის საზოგადოების, შინაგანი მედიცინის წევრი.
    ის არის ევროპული ასოციაციის წევრი პროგნოზირების, პროფილაქტიკური და
    პერსონალიზებული მედიცინის სფეროში.<br />
    2007 წელს მან მიიღო კარლოვი ვარის სამედიცინო სპა ასოციაციის პრიზი
    ბალნეოლოგიაში მრავალწლიანი საქმიანობისთვის და 2019 წელს კარლოვი ვარის
    ჯილდო ბალნეოლოგიაში მრავალწლიანი მუშაობისთვის.<br />
    2010 წელს იყო გამოცემა Clinical balneology-ის მთავარი რედაქტორი.`,
    img: Speaker6,
  },
  {
    id: 12,
    name: "დოქტორი ჟერარ ანდრე",
    position: `მმართველი დირექტორი <br /> Tempo Hospitality Consulting SA`,
    description: `ველნესისა და დასვენების სფეროს ექსპერტს, ჟერარს აქვს სიღრმისეული გამოცდილება
    ჯანმრთელობის, წყლისა და დასვენების, ტურიზმთან დაკავშირებული პროექტების
    მომზადების, დაგეგმვისა და განხორციელებაში. მას ევალება დიზაინი, პროგრამირება
    და მომავალი ოპერაციული და ფინანსური ანალიზის შესრულება, ასევე მართვის
    მოდელების ჩამოყალიბება.<br />
    ჟერარს აქვს 30 წელზე მეტი გამოცდილება წამყვანი თერმული და რეკრეაციული
    აბანოების, ტურისტული კურორტების მართვაში ანდორაში, ბელგიაში, საფრანგეთსა და
    შვეიცარიაში. ის ჩართული იყო ამ კურორტების განვითარების ყველა ფაზაში,
    ზედამხედველობდა მათ დიზაინსა და მშენებლობას, კოორდინაციას უწევდა
    ოპერატორებს და მართავდა მათ საწყის ეტაპზე. ეს ობიექტები ყოველწლიურად
    მასპინძლობს 1,5 მილიონზე მეტ ვიზიტორს და ყველა კურორტი მომგებიანია.
    ჟერარი უწევს საკონსულტაციო მომსახურებას სხვადასხვა საჯარო და კერძო
    პროექტებს და ოპერაციულ მენეჯმენტშია რამდენიმე ქვეყანაში, ძირითადად ევროპაში
    <br />
    და ჩრდილოეთ აფრიკაში; როგორც ექსპერტი განვითარების პროგრამებში E.E.C-ს და
    სხვა ქვეყნები შორის.<br />
    ის არის რამდენიმე მოხსენების ავტორი ტურიზმის, თერმული და ველნესი
    პროექტებისა თუ პროგრამების რამდენიმე სამინისტროსა და სამთავრობო
    უწყებისთვის (ანდორა, ალჟირი, საფრანგეთი, მაროკო, ტუნისი,…).
    ჟერარი შვეიცარიელი და ფრანგია. ფლობს სამართლის დოქტორის ხარისხს ფრანგულ
    სერტიფიკატს პოლიტიკურ მეცნიერებებში.`,
    img: ger,
  },
  {
    id: 13,
    name: "სერჯიო ჯანესინი",
    position: `MD, PhD, FACS
    ასოცირებული პროფესორი
    იტალიის ფერარას უნივერსიტეტი <br />
    აშშ-ს ბეთესდაშის უნივერსიტეტი`,
    description: `სერჯიო ჯანესინი არის იტალიის, ფერარას უნივერსიტეტის ქირურგი და გულ-
    სისხლძარღვთა ქირურგიის ასოცირებული პროფესორი. მისი ინტერესის სფეროა ვენების
    და ლიმფური სისტემის ფუნდამენტური შესწავლა, კლინიკური პრაქტიკა და კვლევები.
    იგი არის მედიცინის სკოლის მუდმივმოქმედი აკადემიური წევრი, სისხლძარღვთა
    ქირურგიის რეზიდენტურის პროგრამის, ფიზიოთერაპიის კურსებისა და
    სტომატოლოგიის სკოლის ლექტორი და აშშ-ს ბეთესდაშის უნივერსიტეტის ჯანდაცვის
    მეცნიერებათა ერთიანი სერვისების ქირურგიის ასოცირებული პროფესორი. მინიჭებული
    აქვს დოქტორის ხარისხი სისხლძარღვთა სისტემის მოლეკულურ პათოფიზიოლოგიაში
    და ასწავლის „მოწინავე თერაპიისა და ექსპერიმენტული ფარმაკოლოგიის“ სადოქტორო
    კურსს ფერარას უნივერსიტეტში. <br />
    ფერარას უნივერსიტეტის სადოქტორო პროგრამების ფარგლებში, ის ჩართულია
    ინტერნაციონალიზაციის პროცესში და ხელს უწყობს სხვადასხვა ქვეყნის
    უნივერსიტეტების ურთიერთთანამშრომლობას. იტალიაში, პადუის უნივერსიტეტის
    მეორე საფეხურის სამაგისტრო პროგრამაზე ანგიოლოგიის დარგში მოწვეული
    პროფესორის პოზიციას იკავებს.<br />
    სერჯიო ჯანესინი ალბანეთის უნივერსიტეტის მოწვეული პროფესორია. იგი არის
    ჟურნალ Vascular Insights-ის მთავარი რედაქტორი და Phlebology Journal-ისა და International
    Angiology Journal-ის ასოცირებული რედაქტორი. ასევე, ჟურნალ Vascular Surgery venous &amp;
    Lymphatic Disorders-ს, ჩინური სამედიცინო ჟურნალის, ქირურგიული მეცნიერებათა
    ჟურნალის და სისხლძარღვთა ქირურგიის თურქული ჟურნალის სარედაქციო გუნდის
    წევრი. მან შექმნა სამი სახელმძღვანელო და ვენურ-ლიმფურ თემებზე გამოქვეყნებული
    აქვს 80-ზე მეტი სამეცნიერო ნაშრომი. წაკითხული აქვს 380-ზე მეტი ლექცია მსოფლიოს
    მასშტაბით.<br />
    სერჯიო არის ვენურ-ლიმფური მსოფლიო საერთაშორისო ქსელის (v-WIN) ფონდის
    დამფუძნებელი, რომლის ფარგლებშიც მის მიერ შემუშავებული პროექტები გაეროს
    კომუნიკაციების საკოორდინაციო კომიტეტის მიერ ოფიციალურად იქნა აღიარებული და
    2021-2022 Universal Expo World-ის გამოფენაზე ფონდის ინსტიტუციონალური
    წარმომადგენელი გახდა.<br />
    2018-2022 წლებში სერჯიო იყო UIP-ის ვიცე-პრეზიდენტი, ხოლო 2023 წლიდან UIP-ის
    მოქმედი პრეზიდენტია.<br />
    იგი არის Ferrara-ს Science Per Aquam University-ის პროექტის დამფუძნებელი, რომელიც სპა
    მკურნალობის მტკიცებულებებზე დაფუძნებულ მეცნიერებას ეძღვნება და
    განსაკუთრებულ აქცენტს, მულტი სპეციალობის ჩართულობით, ვენურ-ლიმფურ
    დრენაჟზე აკეთებს.<br />
    ამჟამად, იგი აბუ დაბის Global Care Hospital Vein-Lympahtic Clinic-ის პარტნიორი
    დირექტორია.`,
    img: sergio,
  },
  {
    id: 14,
    name: "ნინო ჩადუნელი",
    position: `სტუდენტი <br />
    ილიას სახელმწიფო უნივერსიტეტი`,
    description: `ნინო ჩადუნელი 20 წლის. მან 2020 წელს ჩააბარა ილიას სახელმწიფო უნივერსიტეტში,
    ბიზნეს ტექნოლოგისაა და განათლების ფაკულტეტი ძირითადი სპეციალობა ტურიზმი.
    იგი გატაცებულია მოგზაურობით, სამი წელია უკვე ყოველ თვიურად დადის
    საქართველოს სხვადასხვა კუთხეში, მას აქვს საკუთარი ტურისტული სააგენტო და
    საზოგადოებას სთავაზობს ტურებს ყოველ თვიურად. ნინო აქტიურად მონაწილეობს
    საოჯახო ბიზნესის განვითარებაში. სურამში დაბადებულ და გაზრდილ ნინოს სურს
    თავის წვლილი შეიტანოს კურორტ სურამის განვითარებაში. ნინოს დიდი სურვილი აქვს
    სწავლა გააგრძელოს საზღვარგარეთ და ამისთვის უკვე ემზადება.`,
    img: Student,
  },
  {
    id: 15,
    name: "თამარ ჩილინგარაშვილი",
    position: `პროფესორი, ექიმი-კურორტოლოგი <br />
   თბილისის ბანეოლოგიური კურორტი`,
    description: ``,
    img: SR,
  },
];
