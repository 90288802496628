export const countriesList = [
  {
    id: 1,
    nameEN: "ANDORRA",
    nameGE: "ანდორა",
    emoji: "🇦🇩",
    code: "+376",
    shortName: "AD",
  },
  {
    id: 2,
    nameEN: "UNITED ARAB EMIRATES",
    nameGE: "არაბთა გაერთიანებული საამიროები",
    emoji: "🇦🇪",
    code: "+971",
    shortName: "AE",
  },
  {
    id: 3,
    nameEN: "AFGHANISTAN",
    nameGE: "ავღანეთი",
    emoji: "🇦🇫",
    code: "+93",
    shortName: "AF",
  },
  {
    id: 4,
    nameEN: "ANTIGUA AND BARBUDA",
    nameGE: "ანტიგუა-ბარბუდა",
    emoji: "🇦🇬",
    code: "+1268",
    shortName: "AG",
  },
  {
    id: 5,
    nameEN: "ANGUILLA",
    nameGE: "ანგილია",
    emoji: "🇦🇮",
    code: "+1264",
    shortName: "AI",
  },
  {
    id: 6,
    nameEN: "ALBANIA",
    nameGE: "ალბანეთი",
    emoji: "🇦🇱",
    code: "+355",
    shortName: "AL",
  },
  {
    id: 7,
    nameEN: "ARMENIA",
    nameGE: "სომხეთი",
    emoji: "🇦🇲",
    code: "+374",
    shortName: "AM",
  },
  {
    id: 8,
    nameEN: "NETHERLANDS ANTILLES",
    nameGE: "ნიდერლანდების ანტილიები",
    emoji: null,
    code: null,
    shortName: "AN",
  },
  {
    id: 9,
    nameEN: "ANGOLA",
    nameGE: "ანგოლა",
    emoji: "🇦🇴",
    code: "+244",
    shortName: "AO",
  },
  {
    id: 10,
    nameEN: "ANTARCTICA",
    nameGE: "ანტარქტიდა",
    emoji: "🇦🇶",
    code: "+672",
    shortName: "AQ",
  },
  {
    id: 11,
    nameEN: "ARGENTINA",
    nameGE: "არგენტინა",
    emoji: "🇦🇷",
    code: "+54",
    shortName: "AR",
  },
  {
    id: 12,
    nameEN: "AMERICAN SAMOA",
    nameGE: "ამერიკის სამოა",
    emoji: "🇦🇸",
    code: "+1684",
    shortName: "AS",
  },
  {
    id: 13,
    nameEN: "AUSTRIA",
    nameGE: "ავსტრია",
    emoji: "🇦🇹",
    code: "+43",
    shortName: "AT",
  },
  {
    id: 14,
    nameEN: "AUSTRALIA",
    nameGE: "ავსტრალია",
    emoji: "🇦🇺",
    code: "+61",
    shortName: "AU",
  },
  {
    id: 15,
    nameEN: "ARUBA",
    nameGE: "არუბა",
    emoji: "🇦🇼",
    code: "+297",
    shortName: "AW",
  },
  {
    id: 16,
    nameEN: "ALAND ISLANDS",
    nameGE: "ალანდის კუნძულები",
    emoji: null,
    code: null,
    shortName: "AX",
  },
  {
    id: 17,
    nameEN: "AZERBAIJAN",
    nameGE: "აზერბაიჯანი",
    emoji: "🇦🇿",
    code: "+994",
    shortName: "AZ",
  },
  {
    id: 18,
    nameEN: "BOSNIA AND HERZEGOVINA",
    nameGE: "ბოსნია-ჰერცოგოვინა",
    emoji: "🇧🇦",
    code: "+387",
    shortName: "BA",
  },
  {
    id: 19,
    nameEN: "BARBADOS",
    nameGE: "ბარბადოსი",
    emoji: "🇧🇧",
    code: "+1",
    shortName: "BB",
  },
  {
    id: 20,
    nameEN: "BANGLADESH",
    nameGE: "ბანგლადეში",
    emoji: "🇧🇩",
    code: "+880",
    shortName: "BD",
  },
  {
    id: 21,
    nameEN: "BELGIUM",
    nameGE: "ბელგია",
    emoji: "🇧🇪",
    code: "+32",
    shortName: "BE",
  },
  {
    id: 22,
    nameEN: "BURKINA FASO",
    nameGE: "ბურკინა ფასო",
    emoji: "🇧🇫",
    code: "+226",
    shortName: "BF",
  },
  {
    id: 23,
    nameEN: "BULGARIA",
    nameGE: "ბულგარეთი",
    emoji: "🇧🇬",
    code: "+359",
    shortName: "BG",
  },
  {
    id: 24,
    nameEN: "BAHRAIN",
    nameGE: "ბაჰრეინი",
    emoji: "🇧🇭",
    code: "+973",
    shortName: "BH",
  },
  {
    id: 25,
    nameEN: "BURUNDI",
    nameGE: "ბურუნდი",
    emoji: "🇧🇮",
    code: "+257",
    shortName: "BI",
  },
  {
    id: 26,
    nameEN: "BENIN",
    nameGE: "ბენინი",
    emoji: "🇧🇯",
    code: "+229",
    shortName: "BJ",
  },
  {
    id: 27,
    nameEN: "SAINT BARTHიLEMY",
    nameGE: "სენ-ბართელმი",
    emoji: "🇧🇱",
    code: "+590",
    shortName: "BL",
  },
  {
    id: 28,
    nameEN: "BERMUDA",
    nameGE: "ბერმუდა",
    emoji: "🇧🇲",
    code: "+1",
    shortName: "BM",
  },
  {
    id: 29,
    nameEN: "BRUNEI DARUSSALAM",
    nameGE: "ბრუნეი დარუსალამი",
    emoji: "🇧🇳",
    code: "+673",
    shortName: "BN",
  },
  {
    id: 30,
    nameEN: "BOLIVIA",
    nameGE: "ბოლივია",
    emoji: "🇧🇴",
    code: "+591",
    shortName: "BO",
  },
  {
    id: 31,
    nameEN: "BONAIRE, ST. EUSTATIUS, AND SABA",
    nameGE: "ბონაირე, სინტ ეუსტატია და საბა",
    emoji: null,
    code: null,
    shortName: "BQ",
  },
  {
    id: 32,
    nameEN: "BRAZIL",
    nameGE: "ბრაზილია",
    emoji: "🇧🇷",
    code: "+55",
    shortName: "BR",
  },
  {
    id: 33,
    nameEN: "BAHAMAS",
    nameGE: "ბაჰამის კუნძულები",
    emoji: "🇧🇸",
    code: "+1",
    shortName: "BS",
  },
  {
    id: 34,
    nameEN: "BHUTAN",
    nameGE: "ბუტანი",
    emoji: "🇧🇹",
    code: "+975",
    shortName: "BT",
  },
  {
    id: 35,
    nameEN: "BOUVET ISLAND",
    nameGE: "ბუვეტის კუნძული",
    emoji: null,
    code: null,
    shortName: "BV",
  },
  {
    id: 36,
    nameEN: "BOTSWANA",
    nameGE: "ბოტსვანა",
    emoji: "🇧🇼",
    code: "+267",
    shortName: "BW",
  },
  {
    id: 37,
    nameEN: "BELARUS",
    nameGE: "ბელარუსი",
    emoji: "🇧🇾",
    code: "+375",
    shortName: "BY",
  },
  {
    id: 38,
    nameEN: "BELIZE",
    nameGE: "ბელიზი",
    emoji: "🇧🇿",
    code: "+501",
    shortName: "BZ",
  },
  {
    id: 39,
    nameEN: "CANADA",
    nameGE: "კანადა",
    emoji: "🇨🇦",
    code: "+1",
    shortName: "CA",
  },
  {
    id: 40,
    nameEN: "COCOS (KEELING) ISLANDS",
    nameGE: "ქოქოსის (კილინგის) კუნძულები",
    emoji: null,
    code: null,
    shortName: "CC",
  },
  {
    id: 41,
    nameEN: "CONGO, THE DEMOCRATIC REPUBLIC OF THE (formerly Zaire)",
    nameGE: "კონგოს დემოკრატიული რესპუბლიკა",
    emoji: "🇨🇩",
    code: "+243",
    shortName: "CD",
  },
  {
    id: 42,
    nameEN: "CENTRAL AFRICAN REPUBLIC",
    nameGE: "ცენტრალური აფრიკის რესპუბლიკა",
    emoji: "🇨🇫",
    code: "+236",
    shortName: "CF",
  },
  {
    id: 43,
    nameEN: "CONGO, REPUBLIC OF",
    nameGE: "კონგო",
    emoji: "🇨🇬",
    code: "+242",
    shortName: "CG",
  },
  {
    id: 44,
    nameEN: "SWITZERLAND (Confederation of Helvetia)",
    nameGE: "შვეიცარია",
    emoji: "🇨🇭",
    code: "+41",
    shortName: "CH",
  },
  {
    id: 45,
    nameEN: "CტTE D'IVOIRE (Ivory Coast)",
    nameGE: "კოტდივუარი",
    emoji: "🇨🇮",
    code: "+225",
    shortName: "CI",
  },
  {
    id: 46,
    nameEN: "COOK ISLANDS",
    nameGE: "კუკის კუნძულები",
    emoji: "🇨🇰",
    code: "+682",
    shortName: "CK",
  },
  {
    id: 47,
    nameEN: "CHILE",
    nameGE: "ჩილე",
    emoji: "🇨🇱",
    code: "+56",
    shortName: "CL",
  },
  {
    id: 48,
    nameEN: "CAMEROON",
    nameGE: "კამერუნი",
    emoji: "🇨🇲",
    code: "+237",
    shortName: "CM",
  },
  {
    id: 49,
    nameEN: "CHINA",
    nameGE: "ჩინეთი",
    emoji: "🇨🇳",
    code: "+86",
    shortName: "CN",
  },
  {
    id: 50,
    nameEN: "COLOMBIA",
    nameGE: "კოლუმბია",
    emoji: "🇨🇴",
    code: "+57",
    shortName: "CO",
  },
  {
    id: 51,
    nameEN: "COSTA RICA",
    nameGE: "კოსტა-რიკა",
    emoji: "🇨🇷",
    code: "+506",
    shortName: "CR",
  },
  {
    id: 52,
    nameEN: "CUBA",
    nameGE: "კუბა",
    emoji: "🇨🇺",
    code: "+53",
    shortName: "CU",
  },
  {
    id: 53,
    nameEN: "CAPE VERDE",
    nameGE: "კაბო ვერდე",
    emoji: "🇨🇻",
    code: "+238",
    shortName: "CV",
  },
  {
    id: 54,
    nameEN: "CURACAO",
    nameGE: "კურასაო",
    emoji: "🇨🇼",
    code: "+599",
    shortName: "CW",
  },
  {
    id: 55,
    nameEN: "CHRISTMAS ISLAND",
    nameGE: "შობის კუნძული",
    emoji: null,
    code: null,
    shortName: "CX",
  },
  {
    id: 56,
    nameEN: "CYPRUS",
    nameGE: "კვიპროსი",
    emoji: "🇨🇾",
    code: "+357",
    shortName: "CY",
  },
  {
    id: 57,
    nameEN: "CZECH REPUBLIC",
    nameGE: "ჩეხეთის რესპუბლიკა",
    emoji: "🇨🇿",
    code: "+420",
    shortName: "CZ",
  },
  {
    id: 58,
    nameEN: "GERMANY (Deutschland)",
    nameGE: "გერმანია",
    emoji: "🇩🇪",
    code: "+49",
    shortName: "DE",
  },
  {
    id: 59,
    nameEN: "DJIBOUTI",
    nameGE: "ჯიბუტი",
    emoji: "🇩🇯",
    code: "+253",
    shortName: "DJ",
  },
  {
    id: 60,
    nameEN: "DENMARK",
    nameGE: "დანია",
    emoji: "🇩🇰",
    code: "+45",
    shortName: "DK",
  },
  {
    id: 61,
    nameEN: "DOMINICA",
    nameGE: "დომინიკა",
    emoji: "🇩🇲",
    code: "+1",
    shortName: "DM",
  },
  {
    id: 62,
    nameEN: "DOMINICAN REPUBLIC",
    nameGE: "დომინიკელთა რესპუბლიკა",
    emoji: "🇩🇴",
    code: "+1",
    shortName: "DO",
  },
  {
    id: 63,
    nameEN: "ALGERIA (El Djazaïr)",
    nameGE: "ალჟირი",
    emoji: "🇩🇿",
    code: "+213",
    shortName: "DZ",
  },
  {
    id: 64,
    nameEN: "ECUADOR",
    nameGE: "ეკვადორი",
    emoji: "🇪🇨",
    code: "+593",
    shortName: "EC",
  },
  {
    id: 65,
    nameEN: "ESTONIA",
    nameGE: "ესტონეთი",
    emoji: "🇪🇪",
    code: "+372",
    shortName: "EE",
  },
  {
    id: 66,
    nameEN: "EGYPT",
    nameGE: "ეგვიპტე",
    emoji: "🇪🇬",
    code: "+20",
    shortName: "EG",
  },
  {
    id: 67,
    nameEN: "WESTERN SAHARA (formerly Spanish Sahara)",
    nameGE: "დასავლეთი საჰარა",
    emoji: "🇪🇭",
    code: "+212",
    shortName: "EH",
  },
  {
    id: 68,
    nameEN: "ERITREA",
    nameGE: "ერიტრეა",
    emoji: "🇪🇷",
    code: "+291",
    shortName: "ER",
  },
  {
    id: 69,
    nameEN: "SPAIN (España)",
    nameGE: "ესპანეთი",
    emoji: "🇪🇸",
    code: "+34",
    shortName: "ES",
  },
  {
    id: 70,
    nameEN: "ETHIOPIA",
    nameGE: "ეთიოპია",
    emoji: "🇪🇹",
    code: "+251",
    shortName: "ET",
  },
  {
    id: 71,
    nameEN: "FINLAND",
    nameGE: "ფინეთი",
    emoji: "🇫🇮",
    code: "+358",
    shortName: "FI",
  },
  {
    id: 72,
    nameEN: "FIJI",
    nameGE: "ფიჯი",
    emoji: "🇫🇯",
    code: "+679",
    shortName: "FJ",
  },
  {
    id: 73,
    nameEN: "FALKLAND ISLANDS (MALVINAS)",
    nameGE: "ფოლკლენდის (მალვინის) კუნძულები",
    emoji: "🇫🇰",
    code: "+500",
    shortName: "FK",
  },
  {
    id: 74,
    nameEN: "MICRONESIA (Federated States of Micronesia)",
    nameGE: "მიკრონეზია (ფედერალური შტატები)",
    emoji: "🇫🇲",
    code: "+691",
    shortName: "FM",
  },
  {
    id: 75,
    nameEN: "FAEROE ISLANDS",
    nameGE: "ფარერის კუნძულები",
    emoji: "🇫🇴",
    code: "+298",
    shortName: "FO",
  },
  {
    id: 76,
    nameEN: "FRANCE",
    nameGE: "საფრანგეთი",
    emoji: "🇫🇷",
    code: "+33",
    shortName: "FR",
  },
  {
    id: 77,
    nameEN: "GABON",
    nameGE: "გაბონი",
    emoji: "🇬🇦",
    code: "+241",
    shortName: "GA",
  },
  {
    id: 78,
    nameEN: "GREAT BRITAIN (United Kingdom)",
    nameGE: "გაერთიანებული სამეფო",
    emoji: "🇬🇧",
    code: "+44",
    shortName: "GB",
  },
  {
    id: 79,
    nameEN: "GRENADA",
    nameGE: "გრენადა",
    emoji: null,
    code: null,
    shortName: "GD",
  },
  {
    id: 80,
    nameEN: "GEORGIA",
    nameGE: "საქართველო",
    emoji: "🇬🇪",
    code: "+995",
    shortName: "GE",
  },
  {
    id: 81,
    nameEN: "FRENCH GUIANA",
    nameGE: "საფრანგეთის გვიანა",
    emoji: null,
    code: null,
    shortName: "GF",
  },
  {
    id: 82,
    nameEN: "GUERNSEY",
    nameGE: "გერნსი",
    emoji: null,
    code: null,
    shortName: "GG",
  },
  {
    id: 83,
    nameEN: "GHANA",
    nameGE: "განა",
    emoji: "🇬🇭",
    code: "+233",
    shortName: "GH",
  },
  {
    id: 84,
    nameEN: "GIBRALTAR",
    nameGE: "გიბრალტარი",
    emoji: "🇬🇮",
    code: "+350",
    shortName: "GI",
  },
  {
    id: 85,
    nameEN: "GREENLAND",
    nameGE: "გრენლანდია",
    emoji: "🇬🇱",
    code: "+299",
    shortName: "GL",
  },
  {
    id: 86,
    nameEN: "GAMBIA, THE",
    nameGE: "გამბია",
    emoji: "🇬🇲",
    code: "+220",
    shortName: "GM",
  },
  {
    id: 87,
    nameEN: "GUINEA",
    nameGE: "გვინეა",
    emoji: "🇬🇳",
    code: "+224",
    shortName: "GN",
  },
  {
    id: 88,
    nameEN: "GUADELOUPE",
    nameGE: "გვადელუპა",
    emoji: "🇬🇵",
    code: "+590",
    shortName: "GP",
  },
  {
    id: 89,
    nameEN: "EQUATORIAL GUINEA",
    nameGE: "ეკვატორული გვინეა",
    emoji: "🇬🇶",
    code: "+240",
    shortName: "GQ",
  },
  {
    id: 90,
    nameEN: "GREECE",
    nameGE: "საბერძნეთი",
    emoji: "🇬🇷",
    code: "+30",
    shortName: "GR",
  },
  {
    id: 91,
    nameEN: "SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS",
    nameGE: "სამხრეთ ჯორჯია და სამხრეთ სენდვიჩის კუნძულები",
    emoji: null,
    code: null,
    shortName: "GS",
  },
  {
    id: 92,
    nameEN: "GUATEMALA",
    nameGE: "გვატემალა",
    emoji: "🇬🇹",
    code: "+502",
    shortName: "GT",
  },
  {
    id: 93,
    nameEN: "GUAM",
    nameGE: "გუამი",
    emoji: "🇬🇺",
    code: "+1671",
    shortName: "GU",
  },
  {
    id: 94,
    nameEN: "GUINEA-BISSAU",
    nameGE: "გვინეა-ბისაუ",
    emoji: "🇬🇼",
    code: "+245",
    shortName: "GW",
  },
  {
    id: 95,
    nameEN: "GUYANA",
    nameGE: "გაიანა",
    emoji: "🇬🇾",
    code: "+592",
    shortName: "GY",
  },
  {
    id: 96,
    nameEN: "HONG KONG (Special Administrative Region of China)",
    nameGE: "ჰონკონგი",
    emoji: "🇭🇰",
    code: "+852",
    shortName: "HK",
  },
  {
    id: 97,
    nameEN: "HEARD ISLAND AND MCDONALD ISLANDS",
    nameGE: "ჰერდისა და მაკდონალდის კუნძულები",
    emoji: null,
    code: null,
    shortName: "HM",
  },
  {
    id: 98,
    nameEN: "HONDURAS",
    nameGE: "ჰონდურასი",
    emoji: "🇭🇳",
    code: "+504",
    shortName: "HN",
  },
  {
    id: 99,
    nameEN: "CROATIA (Hrvatska)",
    nameGE: "ხორვატია",
    emoji: "🇭🇷",
    code: "+385",
    shortName: "HR",
  },
  {
    id: 100,
    nameEN: "HAITI",
    nameGE: "ჰაიტი",
    emoji: "🇭🇹",
    code: "+509",
    shortName: "HT",
  },
  {
    id: 101,
    nameEN: "HUNGARY",
    nameGE: "უნგრეთი",
    emoji: "🇭🇺",
    code: "+36",
    shortName: "HU",
  },
  {
    id: 102,
    nameEN: "INDONESIA",
    nameGE: "ინდონეზია",
    emoji: "🇮🇩",
    code: "+62",
    shortName: "ID",
  },
  {
    id: 103,
    nameEN: "IRELAND",
    nameGE: "ირლანდია",
    emoji: "🇮🇪",
    code: "+353",
    shortName: "IE",
  },
  {
    id: 104,
    nameEN: "ISRAEL",
    nameGE: "ისრაელი",
    emoji: "🇮🇱",
    code: "+972",
    shortName: "IL",
  },
  {
    id: 105,
    nameEN: "ISLE OF MAN",
    nameGE: "მენის კუნძულები",
    emoji: "🇮🇲",
    code: "+44",
    shortName: "IM",
  },
  {
    id: 106,
    nameEN: "INDIA",
    nameGE: "ინდოეთი",
    emoji: "🇮🇳",
    code: "+91",
    shortName: "IN",
  },
  {
    id: 107,
    nameEN: "BRITISH INDIAN OCEAN TERRITORY",
    nameGE: "ბრიტანეთის ტერიტორია ინდოეთის ოკეანეში",
    emoji: null,
    code: null,
    shortName: "IO",
  },
  {
    id: 108,
    nameEN: "IRAQ",
    nameGE: "ერაყი",
    emoji: "🇮🇶",
    code: "+964",
    shortName: "IQ",
  },
  {
    id: 109,
    nameEN: "IRAN (Islamic Republic of Iran)",
    nameGE: "ირანი (ისლამური რესპუბლიკა)",
    emoji: "🇮🇷",
    code: "+98",
    shortName: "IR",
  },
  {
    id: 110,
    nameEN: "ICELAND",
    nameGE: "ისლანდია",
    emoji: "🇮🇸",
    code: "+354",
    shortName: "IS",
  },
  {
    id: 111,
    nameEN: "ITALY",
    nameGE: "იტალია",
    emoji: "🇮🇹",
    code: "+39",
    shortName: "IT",
  },
  {
    id: 112,
    nameEN: "JERSEY",
    nameGE: "ჯერსი",
    emoji: null,
    code: null,
    shortName: "JE",
  },
  {
    id: 113,
    nameEN: "JAMAICA",
    nameGE: "იამაიკა",
    emoji: "🇯🇲",
    code: "+1",
    shortName: "JM",
  },
  {
    id: 114,
    nameEN: "JORDAN (Hashemite Kingdom of Jordan)",
    nameGE: "იორდანია",
    emoji: "🇯🇴",
    code: "+962",
    shortName: "JO",
  },
  {
    id: 115,
    nameEN: "JAPAN",
    nameGE: "იაპონია",
    emoji: "🇯🇵",
    code: "+81",
    shortName: "JP",
  },
  {
    id: 116,
    nameEN: "KENYA",
    nameGE: "კენია",
    emoji: "🇰🇪",
    code: "+254",
    shortName: "KE",
  },
  {
    id: 117,
    nameEN: "KYRGYZSTAN",
    nameGE: "ყირგიზეთი",
    emoji: "🇰🇬",
    code: "+996",
    shortName: "KG",
  },
  {
    id: 118,
    nameEN: "CAMBODIA",
    nameGE: "კამბოჯა",
    emoji: "🇰🇭",
    code: "+855",
    shortName: "KH",
  },
  {
    id: 119,
    nameEN: "KIRIBATI",
    nameGE: "კირიბატი",
    emoji: "🇰🇮",
    code: "+686",
    shortName: "KI",
  },
  {
    id: 120,
    nameEN: "COMOROS",
    nameGE: "კომორები",
    emoji: "🇰🇲",
    code: "+269",
    shortName: "KM",
  },
  {
    id: 121,
    nameEN: "SAINT KITTS AND NEVIS",
    nameGE: "სენტ-კიტსი და ნევისი",
    emoji: "🇰🇳",
    code: "+1",
    shortName: "KN",
  },
  {
    id: 122,
    nameEN: "KOREA (Democratic Peoples Republic of [North] Korea)",
    nameGE: "კორეის სახალხო დემოკრატიული რესპუბლიკა",
    emoji: "🇰🇵",
    code: "+850",
    shortName: "KP",
  },
  {
    id: 123,
    nameEN: "KOREA (Republic of [South] Korea)",
    nameGE: "კორეის რესპუბლიკა",
    emoji: "🇰🇷",
    code: "+82",
    shortName: "KR",
  },
  {
    id: 124,
    nameEN: "KUWAIT",
    nameGE: "ქუვეითი",
    emoji: "🇰🇼",
    code: "+965",
    shortName: "KW",
  },
  {
    id: 125,
    nameEN: "CAYMAN ISLANDS",
    nameGE: "კაიმანის კუნძულები",
    emoji: "🇰🇾",
    code: "+1-345",
    shortName: "KY",
  },
  {
    id: 126,
    nameEN: "KAZAKHSTAN",
    nameGE: "ყაზახეთი",
    emoji: "🇰🇿",
    code: "+7",
    shortName: "KZ",
  },
  {
    id: 127,
    nameEN: "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    nameGE: "ლაოსის სახალხო დემოკრატიული რესპუბლიკა",
    emoji: "🇱🇦",
    code: "+856",
    shortName: "LA",
  },
  {
    id: 128,
    nameEN: "LEBANON",
    nameGE: "ლიბანი",
    emoji: "🇱🇧",
    code: "+961",
    shortName: "LB",
  },
  {
    id: 129,
    nameEN: "SAINT LUCIA",
    nameGE: "სენტ-ლუსია",
    emoji: "🇱🇨",
    code: "+1",
    shortName: "LC",
  },
  {
    id: 130,
    nameEN: "LIECHTENSTEIN (Fürstentum Liechtenstein)",
    nameGE: "ლიხტენშტეინი",
    emoji: "🇱🇮",
    code: "+423",
    shortName: "LI",
  },
  {
    id: 131,
    nameEN: "SRI LANKA (formerly Ceylon)",
    nameGE: "შრი-ლანკა",
    emoji: "🇱🇰",
    code: "+94",
    shortName: "LK",
  },
  {
    id: 132,
    nameEN: "LIBERIA",
    nameGE: "ლიბერია",
    emoji: "🇱🇷",
    code: "+231",
    shortName: "LR",
  },
  {
    id: 133,
    nameEN: "LESOTHO",
    nameGE: "ლესოტო",
    emoji: "🇱🇸",
    code: "+266",
    shortName: "LS",
  },
  {
    id: 134,
    nameEN: "LITHUANIA",
    nameGE: "ლიტვა",
    emoji: "🇱🇹",
    code: "+370",
    shortName: "LT",
  },
  {
    id: 135,
    nameEN: "LUXEMBOURG",
    nameGE: "ლუქსემბურგი",
    emoji: "🇱🇺",
    code: "+352",
    shortName: "LU",
  },
  {
    id: 136,
    nameEN: "LATVIA",
    nameGE: "ლატვია",
    emoji: "🇱🇻",
    code: "+371",
    shortName: "LV",
  },
  {
    id: 137,
    nameEN: "LIBYA (Libyan Arab Jamahirya)",
    nameGE: "ლიბია",
    emoji: "🇱🇾",
    code: "+218",
    shortName: "LY",
  },
  {
    id: 138,
    nameEN: "MOROCCO",
    nameGE: "მაროკო",
    emoji: "🇲🇦",
    code: "+212",
    shortName: "MA",
  },
  {
    id: 139,
    nameEN: "MONACO",
    nameGE: "მონაკო",
    emoji: "🇲🇨",
    code: "+377",
    shortName: "MC",
  },
  {
    id: 140,
    nameEN: "MOLDOVA",
    nameGE: "მოლდოვა",
    emoji: "🇲🇩",
    code: "+373",
    shortName: "MD",
  },
  {
    id: 141,
    nameEN: "MONTENEGRO",
    nameGE: "მონტენეგრო",
    emoji: "🇲🇪",
    code: "+382",
    shortName: "ME",
  },
  {
    id: 142,
    nameEN: "SAINT MARTIN (French portion)",
    nameGE: "სენ-მარტენი (საფრანგეთის ნაწილი)",
    emoji: "🇲🇫",
    code: "+1599",
    shortName: "MF",
  },
  {
    id: 143,
    nameEN: "MADAGASCAR",
    nameGE: "მადაგასკარი",
    emoji: "🇲🇬",
    code: "+261",
    shortName: "MG",
  },
  {
    id: 144,
    nameEN: "MARSHALL ISLANDS",
    nameGE: "მარშალის კუნძულები",
    emoji: "🇲🇭",
    code: "+692",
    shortName: "MH",
  },
  {
    id: 145,
    nameEN: "MACEDONIA (Former Yugoslav Republic of Macedonia)",
    nameGE: "მაკედონია",
    emoji: "🇲🇰",
    code: "+389",
    shortName: "MK",
  },
  {
    id: 146,
    nameEN: "MALI",
    nameGE: "მალი",
    emoji: "🇲🇱",
    code: "+223",
    shortName: "ML",
  },
  {
    id: 147,
    nameEN: "MYANMAR (formerly Burma)",
    nameGE: "მიანმა",
    emoji: "🇲🇲",
    code: "+95",
    shortName: "MM",
  },
  {
    id: 148,
    nameEN: "MONGOLIA",
    nameGE: "მონღოლეთი",
    emoji: "🇲🇳",
    code: "+976",
    shortName: "MN",
  },
  {
    id: 149,
    nameEN: "MACAO (Special Administrative Region of China)",
    nameGE: "მაკაო",
    emoji: "🇲🇴",
    code: "+853",
    shortName: "MO",
  },
  {
    id: 150,
    nameEN: "NORTHERN MARIANA ISLANDS",
    nameGE: "ჩრდილოეთ მარიანის კუნძულები",
    emoji: "🇲🇵",
    code: "+1670",
    shortName: "MP",
  },
  {
    id: 151,
    nameEN: "MARTINIQUE",
    nameGE: "მარტინიკა",
    emoji: null,
    code: null,
    shortName: "MQ",
  },
  {
    id: 152,
    nameEN: "MAURITANIA",
    nameGE: "მავრიტანია",
    emoji: "🇲🇷",
    code: "+222",
    shortName: "MR",
  },
  {
    id: 153,
    nameEN: "MONTSERRAT",
    nameGE: "მონტსერატი",
    emoji: "🇲🇸",
    code: "+1664",
    shortName: "MS",
  },
  {
    id: 154,
    nameEN: "MALTA",
    nameGE: "მალტა",
    emoji: "🇲🇹",
    code: "+356",
    shortName: "MT",
  },
  {
    id: 155,
    nameEN: "MAURITIUS",
    nameGE: "მავრიკი",
    emoji: "🇲🇺",
    code: "+230",
    shortName: "MU",
  },
  {
    id: 156,
    nameEN: "MALDIVES",
    nameGE: "მალდივები",
    emoji: "🇲🇻",
    code: "+960",
    shortName: "MV",
  },
  {
    id: 157,
    nameEN: "MALAWI",
    nameGE: "მალავი",
    emoji: "🇲🇼",
    code: "+265",
    shortName: "MW",
  },
  {
    id: 158,
    nameEN: "MEXICO",
    nameGE: "მექსიკა",
    emoji: "🇲🇽",
    code: "+52",
    shortName: "MX",
  },
  {
    id: 159,
    nameEN: "MALAYSIA",
    nameGE: "მალაიზია",
    emoji: "🇲🇾",
    code: "+60",
    shortName: "MY",
  },
  {
    id: 160,
    nameEN: "MOZAMBIQUE (Moçambique)",
    nameGE: "მოზამბიკი",
    emoji: "🇲🇿",
    code: "+258",
    shortName: "MZ",
  },
  {
    id: 161,
    nameEN: "NAMIBIA",
    nameGE: "ნამიბია",
    emoji: "🇳🇦",
    code: "+264",
    shortName: "NA",
  },
  {
    id: 162,
    nameEN: "NEW CALEDONIA",
    nameGE: "ახალი კალედონია",
    emoji: "🇳🇨",
    code: "+687",
    shortName: "NC",
  },
  {
    id: 163,
    nameEN: "NIGER",
    nameGE: "ნიგერი",
    emoji: "🇳🇪",
    code: "+227",
    shortName: "NE",
  },
  {
    id: 164,
    nameEN: "NORFOLK ISLAND",
    nameGE: "ნორფოლკის კუნძული",
    emoji: "🇳🇫",
    code: "+672",
    shortName: "NF",
  },
  {
    id: 165,
    nameEN: "NIGERIA",
    nameGE: "ნიგერია",
    emoji: "🇳🇬",
    code: "+234",
    shortName: "NG",
  },
  {
    id: 166,
    nameEN: "NICARAGUA",
    nameGE: "ნიკარაგუა",
    emoji: "🇳🇮",
    code: "+505",
    shortName: "NI",
  },
  {
    id: 167,
    nameEN: "NETHERLANDS",
    nameGE: "ნიდერლანდები",
    emoji: "🇳🇱",
    code: "+31",
    shortName: "NL",
  },
  {
    id: 168,
    nameEN: "NORWAY",
    nameGE: "ნორვეგია",
    emoji: "🇳🇴",
    code: "+47",
    shortName: "NO",
  },
  {
    id: 169,
    nameEN: "NEPAL",
    nameGE: "ნეპალი",
    emoji: "🇳🇵",
    code: "+977",
    shortName: "NP",
  },
  {
    id: 170,
    nameEN: "NAURU",
    nameGE: "ნაურუ",
    emoji: "🇳🇷",
    code: "+674",
    shortName: "NR",
  },
  {
    id: 171,
    nameEN: "NIUE",
    nameGE: "ნიუე",
    emoji: "🇳🇺",
    code: "+683",
    shortName: "NU",
  },
  {
    id: 172,
    nameEN: "NEW ZEALAND",
    nameGE: "ახალი ზელანდია",
    emoji: "🇳🇿",
    code: "+64",
    shortName: "NZ",
  },
  {
    id: 173,
    nameEN: "OMAN",
    nameGE: "ომანი",
    emoji: "🇴🇲",
    code: "+968",
    shortName: "OM",
  },
  {
    id: 174,
    nameEN: "PANAMA",
    nameGE: "პანამა",
    emoji: "🇵🇦",
    code: "+507",
    shortName: "PA",
  },
  {
    id: 175,
    nameEN: "PERU",
    nameGE: "პერუ",
    emoji: "🇵🇪",
    code: "+51",
    shortName: "PE",
  },
  {
    id: 176,
    nameEN: "FRENCH POLYNESIA",
    nameGE: "საფრანგეთის პოლინეზია",
    emoji: "🇵🇫",
    code: "+689",
    shortName: "PF",
  },
  {
    id: 177,
    nameEN: "PAPUA NEW GUINEA",
    nameGE: "პაპუა - ახალი გვინეა",
    emoji: "🇵🇬",
    code: "+675",
    shortName: "PG",
  },
  {
    id: 178,
    nameEN: "PHILIPPINES",
    nameGE: "ფილიპინები",
    emoji: "🇵🇭",
    code: "+63",
    shortName: "PH",
  },
  {
    id: 179,
    nameEN: "PAKISTAN",
    nameGE: "პაკისტანი",
    emoji: "🇵🇰",
    code: "+92",
    shortName: "PK",
  },
  {
    id: 180,
    nameEN: "POLAND",
    nameGE: "პოლონეთი",
    emoji: "🇵🇱",
    code: "+48",
    shortName: "PL",
  },
  {
    id: 181,
    nameEN: "SAINT PIERRE AND MIQUELON",
    nameGE: "სენტ-პიერი და მიქელონი",
    emoji: "🇵🇲",
    code: "+508",
    shortName: "PM",
  },
  {
    id: 182,
    nameEN: "PITCAIRN",
    nameGE: "პიტკერნი",
    emoji: "🇵🇳",
    code: "+870",
    shortName: "PN",
  },
  {
    id: 183,
    nameEN: "PUERTO RICO",
    nameGE: "პუერტო-რიკო",
    emoji: "🇵🇷",
    code: "+1",
    shortName: "PR",
  },
  {
    id: 184,
    nameEN: "PALESTINIAN TERRITORIES",
    nameGE: "პალესტინის სახელმწიფო",
    emoji: null,
    code: null,
    shortName: "PS",
  },
  {
    id: 185,
    nameEN: "PORTUGAL",
    nameGE: "პორტუგალია",
    emoji: "🇵🇹",
    code: "+351",
    shortName: "PT",
  },
  {
    id: 186,
    nameEN: "PALAU",
    nameGE: "პალაუ",
    emoji: "🇵🇼",
    code: "+680",
    shortName: "PW",
  },
  {
    id: 187,
    nameEN: "PARAGUAY",
    nameGE: "პარაგვაი",
    emoji: "🇵🇾",
    code: "+595",
    shortName: "PY",
  },
  {
    id: 188,
    nameEN: "QATAR",
    nameGE: "ყატარი",
    emoji: "🇶🇦",
    code: "+974",
    shortName: "QA",
  },
  {
    id: 189,
    nameEN: "RიUNION",
    nameGE: "რეიუნიონი",
    emoji: "🇷🇪",
    code: "+262",
    shortName: "RE",
  },
  {
    id: 190,
    nameEN: "ROMANIA",
    nameGE: "რუმინეთი",
    emoji: "🇷🇴",
    code: "+40",
    shortName: "RO",
  },
  {
    id: 191,
    nameEN: "SERBIA (Republic of Serbia)",
    nameGE: "სერბეთი",
    emoji: "🇷🇸",
    code: "+381",
    shortName: "RS",
  },
  {
    id: 192,
    nameEN: "RUSSIAN FEDERATION",
    nameGE: "რუსეთის ფედერაცია",
    emoji: "🇷🇺",
    code: "+7",
    shortName: "RU",
  },
  {
    id: 193,
    nameEN: "RWANDA",
    nameGE: "რუანდა",
    emoji: "🇷🇼",
    code: "+250",
    shortName: "RW",
  },
  {
    id: 194,
    nameEN: "SAUDI ARABIA (Kingdom of Saudi Arabia)",
    nameGE: "საუდის არაბეთი",
    emoji: "🇸🇦",
    code: "+966",
    shortName: "SA",
  },
  {
    id: 195,
    nameEN: "SOLOMON ISLANDS",
    nameGE: "სოლომონის კუნძულები",
    emoji: "🇸🇧",
    code: "+677",
    shortName: "SB",
  },
  {
    id: 196,
    nameEN: "SEYCHELLES",
    nameGE: "სეიშელები",
    emoji: "🇸🇨",
    code: "+248",
    shortName: "SC",
  },
  {
    id: 197,
    nameEN: "SUDAN",
    nameGE: "სუდანი",
    emoji: "🇸🇩",
    code: "+249",
    shortName: "SD",
  },
  {
    id: 198,
    nameEN: "SWEDEN",
    nameGE: "შვედეთი",
    emoji: "🇸🇪",
    code: "+46",
    shortName: "SE",
  },
  {
    id: 199,
    nameEN: "SINGAPORE",
    nameGE: "სინგაპური",
    emoji: "🇸🇬",
    code: "+65",
    shortName: "SG",
  },
  {
    id: 200,
    nameEN: "SAINT HELENA",
    nameGE: "სენტელენა",
    emoji: "🇸🇭",
    code: "+290",
    shortName: "SH",
  },
  {
    id: 201,
    nameEN: "SLOVENIA",
    nameGE: "სლოვენია",
    emoji: "🇸🇮",
    code: "+386",
    shortName: "SI",
  },
  {
    id: 202,
    nameEN: "SVALBARD AND JAN MAYEN",
    nameGE: "სვალბარდი-იან-მაიენი",
    emoji: null,
    code: null,
    shortName: "SJ",
  },
  {
    id: 203,
    nameEN: "SLOVAKIA (Slovak Republic)",
    nameGE: "სლოვაკეთი",
    emoji: "🇸🇰",
    code: "+421",
    shortName: "SK",
  },
  {
    id: 204,
    nameEN: "SIERRA LEONE",
    nameGE: "სიერა-ლეონე",
    emoji: "🇸🇱",
    code: "+232",
    shortName: "SL",
  },
  {
    id: 205,
    nameEN: "SAN MARINO (Republic of)",
    nameGE: "სან-მარინო",
    emoji: "🇸🇲",
    code: "+378",
    shortName: "SM",
  },
  {
    id: 206,
    nameEN: "SENEGAL",
    nameGE: "სენეგალი",
    emoji: "🇸🇳",
    code: "+221",
    shortName: "SN",
  },
  {
    id: 207,
    nameEN: "SOMALIA",
    nameGE: "სომალი",
    emoji: "🇸🇴",
    code: "+252",
    shortName: "SO",
  },
  {
    id: 208,
    nameEN: "SURINAME",
    nameGE: "სურინამი",
    emoji: "🇸🇷",
    code: "+597",
    shortName: "SR",
  },
  {
    id: 209,
    nameEN: "SOUTH SUDAN",
    nameGE: "სამხრეთ სუდანი",
    emoji: "🇸🇸",
    code: "+211",
    shortName: "SS",
  },
  {
    id: 210,
    nameEN: "SAO TOME AND PRINCIPE",
    nameGE: "საუტომა-პრენსიპა",
    emoji: "🇸🇹",
    code: "+239",
    shortName: "ST",
  },
  {
    id: 211,
    nameEN: "EL SALVADOR",
    nameGE: "სალვადორი",
    emoji: "🇸🇻",
    code: "+503",
    shortName: "SV",
  },
  {
    id: 212,
    nameEN: "SINT MAARTEN",
    nameGE: "სინტ-მარტენი (ნიდერლანდების ნაწილი)",
    emoji: null,
    code: null,
    shortName: "SX",
  },
  {
    id: 213,
    nameEN: "SYRIAN ARAB REPUBLIC",
    nameGE: "სირიის არაბთა რესპუბლიკა",
    emoji: "🇸🇾",
    code: "+963",
    shortName: "SY",
  },
  {
    id: 214,
    nameEN: "SWAZILAND",
    nameGE: "სვაზილენდი",
    emoji: "🇸🇿",
    code: "+268",
    shortName: "SZ",
  },
  {
    id: 215,
    nameEN: "TURKS AND CAICOS ISLANDS",
    nameGE: "ტერკს-კაიკოსის კუნძულები",
    emoji: null,
    code: null,
    shortName: "TC",
  },
  {
    id: 216,
    nameEN: "CHAD (Tchad)",
    nameGE: "ჩადი",
    emoji: null,
    code: null,
    shortName: "TD",
  },
  {
    id: 217,
    nameEN: "FRENCH SOUTHERN TERRITORIES",
    nameGE: "საფრანგეთის სამხრეთ ტერიტორიები",
    emoji: null,
    code: null,
    shortName: "TF",
  },
  {
    id: 218,
    nameEN: "TOGO",
    nameGE: "ტოგო",
    emoji: "🇹🇬",
    code: "+228",
    shortName: "TG",
  },
  {
    id: 219,
    nameEN: "THAILAND",
    nameGE: "ტაილანდი",
    emoji: "🇹🇭",
    code: "+66",
    shortName: "TH",
  },
  {
    id: 220,
    nameEN: "TAJIKISTAN",
    nameGE: "ტაჯიკეთი",
    emoji: "🇹🇯",
    code: "+992",
    shortName: "TJ",
  },
  {
    id: 221,
    nameEN: "TOKELAU",
    nameGE: "ტოკელაუ",
    emoji: "🇹🇰",
    code: "+690",
    shortName: "TK",
  },
  {
    id: 222,
    nameEN: "TIMOR-LESTE (formerly East Timor)",
    nameGE: "ტიმორ-ლესტე",
    emoji: "🇹🇱",
    code: "+670",
    shortName: "TL",
  },
  {
    id: 223,
    nameEN: "TURKMENISTAN",
    nameGE: "თურქმენეთი",
    emoji: "🇹🇲",
    code: "+993",
    shortName: "TM",
  },
  {
    id: 224,
    nameEN: "TUNISIA",
    nameGE: "ტუნისი",
    emoji: "🇹🇳",
    code: "+216",
    shortName: "TN",
  },
  {
    id: 225,
    nameEN: "TONGA",
    nameGE: "ტონგა",
    emoji: null,
    code: null,
    shortName: "TO",
  },
  {
    id: 226,
    nameEN: "TURKEY",
    nameGE: "თურქეთი",
    emoji: "🇹🇷",
    code: "+90",
    shortName: "TR",
  },
  {
    id: 227,
    nameEN: "TRINIDAD AND TOBAGO",
    nameGE: "ტრინიდად-ტობაგო",
    emoji: "🇹🇹",
    code: "+1",
    shortName: "TT",
  },
  {
    id: 228,
    nameEN: "TUVALU",
    nameGE: "ტუვალუ",
    emoji: "🇹🇻",
    code: "+688",
    shortName: "TV",
  },
  {
    id: 229,
    nameEN: 'TAIWAN ("Chinese Taipei" for IOC)',
    nameGE: "ტაივანი, ჩინეთის პროვინცია",
    emoji: "🇹🇼",
    code: "+886",
    shortName: "TW",
  },
  {
    id: 230,
    nameEN: "TANZANIA",
    nameGE: "ტანზანიის გაერთიანებული რესპუბლიკა",
    emoji: "🇹🇿",
    code: "+255",
    shortName: "TZ",
  },
  {
    id: 231,
    nameEN: "UKRAINE",
    nameGE: "უკრაინა",
    emoji: "🇺🇦",
    code: "+380",
    shortName: "UA",
  },
  {
    id: 232,
    nameEN: "UGANDA",
    nameGE: "უგანდა",
    emoji: "🇺🇬",
    code: "+256",
    shortName: "UG",
  },
  {
    id: 233,
    nameEN: "UNITED STATES MINOR OUTLYING ISLANDS",
    nameGE: "შეერთებული შტატების შორსმდებარე მცირე კუნძულები",
    emoji: null,
    code: null,
    shortName: "UM",
  },
  {
    id: 234,
    nameEN: "UNITED STATES",
    nameGE: "აშშ",
    emoji: "🇺🇸",
    code: "+1",
    shortName: "US",
  },
  {
    id: 235,
    nameEN: "URUGUAY",
    nameGE: "ურუგვაი",
    emoji: "🇺🇾",
    code: "+598",
    shortName: "UY",
  },
  {
    id: 236,
    nameEN: "UZBEKISTAN",
    nameGE: "უზბეკეთი",
    emoji: "🇺🇿",
    code: "+998",
    shortName: "UZ",
  },
  {
    id: 237,
    nameEN: "VATICAN CITY (Holy See)",
    nameGE: "ვატიკანი, ქალაქი-სახელმწიფო",
    emoji: "🇻🇦",
    code: "+39",
    shortName: "VA",
  },
  {
    id: 238,
    nameEN: "SAINT VINCENT AND THE GRENADINES",
    nameGE: "სენტ-ვინსენტ-გრენადინები",
    emoji: "🇻🇨",
    code: "+1",
    shortName: "VC",
  },
  {
    id: 239,
    nameEN: "VENEZUELA",
    nameGE: "ვენესუელა",
    emoji: "🇻🇪",
    code: "+58",
    shortName: "VE",
  },
  {
    id: 240,
    nameEN: "VIRGIN ISLANDS, BRITISH",
    nameGE: "ვირჯინიის კუნძულები (ბრიტ.)",
    emoji: "🇻🇬",
    code: "+1284",
    shortName: "VG",
  },
  {
    id: 241,
    nameEN: "VIRGIN ISLANDS, U.S.",
    nameGE: "ვირჯინიის კუნძულები (აშშ)",
    emoji: null,
    code: null,
    shortName: "VI",
  },
  {
    id: 242,
    nameEN: "VIET NAM",
    nameGE: "ვიეტნამი",
    emoji: "🇻🇳",
    code: "+84",
    shortName: "VN",
  },
  {
    id: 243,
    nameEN: "VANUATU",
    nameGE: "ვანუატუ",
    emoji: "🇻🇺",
    code: "+678",
    shortName: "VU",
  },
  {
    id: 244,
    nameEN: "WALLIS AND FUTUNA",
    nameGE: "უოლის-ფუტუნა",
    emoji: null,
    code: null,
    shortName: "WF",
  },
  {
    id: 245,
    nameEN: "SAMOA (formerly Western Samoa)",
    nameGE: "სამოა",
    emoji: "🇼🇸",
    code: "+685",
    shortName: "WS",
  },
  {
    id: 246,
    nameEN: "YEMEN (Yemen Arab Republic)",
    nameGE: "იემენი",
    emoji: "🇾🇪",
    code: "+967",
    shortName: "YE",
  },
  {
    id: 247,
    nameEN: "MAYOTTE",
    nameGE: "მეიოტი",
    emoji: null,
    code: null,
    shortName: "YT",
  },
  {
    id: 248,
    nameEN: "SOUTH AFRICA (Zuid Afrika)",
    nameGE: "სამხრეთ აფრიკა",
    emoji: "🇿🇦",
    code: "+27",
    shortName: "ZA",
  },
  {
    id: 249,
    nameEN: "ZAMBIA (formerly Northern Rhodesia)",
    nameGE: "ზამბია",
    emoji: "🇿🇲",
    code: "+260",
    shortName: "ZM",
  },
  {
    id: 250,
    nameEN: "ZIMBABWE",
    nameGE: "ზიმბაბვე",
    emoji: "🇿🇼",
    code: "+263",
    shortName: "ZW",
  },
];
