import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Login({ setIsAuthenticated }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigation = useNavigate();
  const [errors, setErrors] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "api/home/Authorization",
        {
          Username: username,
          Password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.success) {
        setIsAuthenticated(response.data);
        localStorage.setItem("token", response?.data?.token);
        return;
      }

      setErrors(response.data.success);
      localStorage.setItem("token", "");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form
      className="login-form container p-4 p-md-0"
      onSubmit={handleSubmit}
      style={{ color: "white" }}
    >
      <h4 className="text-center">Authorization</h4>
      <div className="mb-3">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Username
        </label>
        <input
          type="text"
          value={username}
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="exampleInputPassword1" className="form-label">
          Password
        </label>
        <input
          type="password"
          value={password}
          className="form-control"
          id="exampleInputPassword1"
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      {errors === false && (
        <div id="emailHelp" className="form-text text-danger">
          Username/Password is incorrect
        </div>
      )}
      <div className="text-center mt-5">
        <button
          type="submit"
          className="btn btn-secondary register-now-button register-v4-2024 login-button"
          disabled={username === "" || password === ""}
        >
          Login
        </button>
      </div>
    </form>
  );
}

export default Login;
