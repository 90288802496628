// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        topbarHome: "Home",
        backgroundImageTitle2023: `1<sup style="text-transform: none">st</sup> INTERNATIONAL CONFERENCE`,
        backgroundImageTitle: `2nd INTERNATIONAL CONFERENCE`,
        backgroundImageTitle2:
          "TRENDS AND CHALLENGES OF THE RESORTS SUSTAINABLE DEVELOPMENT",
        backgroundImageDate2023:
          "7<sup>th</sup> December 2023<br /> Tbilisi, Georgia",
        backgroundImageDate: "December 5, 2024<br /> Tbilisi, Georgia",
        backgroundImageRegister: "Register Now",
        navbarConferance: "Conference",
        navbarProgram: "Programme",
        navbarOrg: "Organiser ",
        navbarReg: "Registration",
        navbarContact: "Contact",
        conferanceMission: "Mission",
        conferance1: `1<sup>st</sup> International Conference “Trends and Challenges of the Resorts Sustainable Development” aims to identify emerging trends and challenges in sustainable resort development, exploring opportunities to maximize economic potential and social benefits through the development of climate and balneological resorts. 
        The conference will discuss the need to develop the resort industry and the most effective international resort management models. It is an excellent opportunity to devise a new strategic approach to resort industry development 
        `,
        conferance2: `The conference will address the need to develop attractive destinations to stimulate activities in these areas and encourage businesses to invest in field development.
        The conference will offer an overview of resort destinations within a broader context and will cover topics related to three major pillars: 
        `,
        conferance3: `<ul>
        <li>
        Resorts Sustainable Development: Trends, Challenges, and Future Prospects
        </li>
        <li>Wellness and Balneology in Healthcare and Well-being</li>
        <li>Economic and Social Impacts of Resort Development</li>
      </ul>
      <br />
      This conference will bring together experts from both the local and international resort industry <br />
      <br />
      <b>Participants:</b> <br /> Approximately 400 participants, including government officials, local authorities, DMOs, private sector representatives, international organizations and academia
      <br />
      <br /> <b>Official Languages:</b> Georgian and English`,
        footerText: `All Rights Reserved. Designed by MidNight™.`,
        georgiaInfo1: `Georgia is situated at the crossroads of Europe and Asia, to the east of the Black sea. <br /> <br />
        The country's strategic geopolitical location has the potential to attract visitors from all over the world. It is a cluster of diverse cultures, religions, stunning landscapes, and ancient history  <br />
        <br />`,
        georgiaInfo2: `Georgia has a total of 271 resorts and resort areas. Georgia’s rich resort landscape incorporates thermal, sea, mountain, ski, climate and lake resorts which are situated in picturesque locations surrounded by UNESCO heritage sites and historical and cultural monuments `,
        georgiaTitle: "About Georgia",
        speakers2: "Speakers",
        raedMore: "Read more",
        orgParag: `The LEPL Resorts Development Agency was established in 2022 on the initiative of the Prime Minister of Georgia and operates under the Ministry of Economy and Sustainable Development of Georgia. 
        Considering the huge potential of Georgia’s natural and cultural resources and diversified resorts, including, wellness, mountain, thermal, lake and sea resorts. The Government of Georgia has designated resorts development as one of the Country’s key priority to foster economic development.
        `,
        orgParag1: `
        Mission of the Resorts Development Agency:
        <br /> <br />
        <ul>
          <li>
          Develop policies and development concept and strategy of the resorts based on the state policy and feasibility studies;
          </li>
          <li>
          	Enhance overall resort industry and ultimately, drive economic growth, and regional development;
          </li>
          <li>
          Identify and promote resorts and potential resort areas;
          </li>
          <li>Promote infrastructure development in resort areas;</li>
          <li>
          Establish close cooperation with private, civil, and public organizations, municipal self-governing bodies, and international organizations;
          </li>
          <li>
          Implement EU standards, regulations, management models and best practices;
          </li>
          <li>
          Develop educational programs and professional standards.
          </li>
        </ul>`,
        successMess: "You have successfully registered",
        successMessQR: "Present received QR code at the event",
        mapTitle: "Venue",
        mapAddress: "20 Telavi Str Tbilisi 0103 Georgia",
        mapAddress2024: `3 Lado Gudiashvili St,   
Tbilisi 1105, Georgia`,
        contactAddress: "7 A.Politkovskaya Str. Tbilisi 0186, Georgia",
        name: "Name",
        surname: "Surname",
        country: "Country",
        email: "E-mail",
        organization: "Organisation",
        position: "Position",
        mobileNumber: "Telephone numbers",
        invalidEmail: "Email is not valid!",
        seeLess: "Read less",
        mapAddressParag: "Sheraton Grand Tbilisi Metekhi Palace",
        mapAddressParag2024: "Wyndham Grand Tbilisi",
        visitGeorgia: "Visit Georgia",
        welcomeGuys: "Welcome Speech",
        companyNameG: "LEPL Resorts Development Agency",
        processing: "Request is processing...",
        welcome: "Welcome",
        thankYou: "Thank you for coming!",
        guesNotFOund: "404 Error. Guest Not Found!",
        backToAdmin: "Go back to admin panel",
        successCheckIn: "You have successfully checked in",
        checkInAgain: "You have already checked in",

        events: "Events",
        mapAddress2024: "3 Lado Gudiashvili St, Tbilisi 1105, Georgia",
        backgroundImageTitle2024: "Future of Resorts Development",
        logisticAndTravel: "Logistic & travel",
        conference2024Part1: `The overall objective of the 2nd International Conference “Future of Resorts Development” is to explore core topics related to the transformation of resorts into modern destinations, adapted to current development trends and challenges. The conference will discuss future strategies for the resort industry, focusing on balneological and climate resorts and addressing the issues related sustainable destinations, economic growth, health and wellness tourism, and social well-being. `,
        conference2024Part2: `The conference will focus on the following topics: <br /> <ul>
      <li>
     Resorts sustainable development: Ensuring that resorts adopt practices that preserve natural resources and reduce their environmental impact and developed strategies are adapted to the climate change and other challenges.
      </li>
      <li>Transforming and modernizing resorts: Guiding the transformation of traditional resorts into modern destinations that meet the demands of travelers and new trends of developments while staying aligned with sustainable and health-focused practices.  </li>
      <li>Enhancing health and wellness offerings: Integrating health and wellness into resort experiences, making these destinations not just places for relaxation but also for enhancing physical and mental well-being.</li>
        <li>Maximizing economic benefits: Identifying economic opportunities that sustainable resorts can offer to local communities and the broader economy, including job creation and sustainable tourism growth.</li>
        <li> Innovation: Offering innovative solutions, including information technology, for achieving the sustainable development of resorts. </li>
        <li>Setting a vision for the future: Establishing a clear vision and actionable strategies for the long-term sustainability and success of resorts, ensuring they remain competitive and attractive in a rapidly changing world. </li>
        </ul>
    <br />
   The conference will cover the following sessions: <br />
    <b>Session 1: Modernizing Resorts into Sustainable Destinations </b> <br/>
    <b>Session 2: Shaping the future of Wellness, Health and Spa Resorts Industry </b> </b> 
    
    <br /> The conference will bring together local and international leaders along with industry experts from the mountain and thermal resort sector. The speakers will include experienced scientists, specialists, and researchers with extensive backgrounds in both research and resort management. 
    <br />
    <br /> <b>The conference will be conducted in English and Georgian languages.`,
        firstInternConf: `2023`,

        agree1:
          "LEPL Resorts Development Agency collects confidential personal data in accordance with the Law of Georgia on Personal Data Protection. Individuals whose data is collected have the right to access their information and request corrections, updates, additions, blocking, deletion, or destruction of their personal data (For questions, please contact us at: info@resorts.gov.ge). ",
        agree2: `During the 2nd  international conference "Future of Resorts Development," photo and video recording will take place. Visual materials will be shared on our websites and social media platforms.

`,
        hasAgreed: "I have read and agreed to the terms and conditions.",

        moderators: "Moderators"
      },
    },

    ///////////////////////////////////////// ka
    ka: {
      translation: {
        topbarHome: "მთავარი",
        backgroundImageTitle2023: "პირველი საერთაშორისო კონფერენცია",
        firstInternConf: `2023`,
        backgroundImageTitle: "მე-2 საერთაშორისო კონფერენცია",
        backgroundImageTitle2:
          "კურორტების მდგრადი განვითარების ტენდენციები და გამოწვევები",
        backgroundImageDate2023: "7 დეკემბერი 2023 <br /> თბილისი, საქართველო",
        backgroundImageDate: "5 დეკემბერი 2024 <br /> თბილისი, საქართველო",
        backgroundImageRegister: "რეგისტრაცია",
        navbarConferance: "კონფერენცია",
        navbarProgram: "პროგრამა",
        navbarOrg: "ორგანიზატორი",
        navbarReg: "რეგისტრაცია",
        navbarContact: "კონტაქტი",
        conferanceMission: "მისია",
        conferance1: `პირველი საერთაშორისო კონფერენცია „კურორტების მდგრადი განვითარების ტენდენციები და გამოწვევები“ მიზნად ისახავს კურორტების მდგრადი განვითარების ტენდენციებისა და გამოწვევების იდენტიფიცირებას და განიხილავს კლიმატური და ბალნეოლოგიური კურორტების განვითარების გზით, ეკონომიკური პოტენციალისა და სოციალური სარგებლის მაქსიმალურად გაზრდის შესაძლებლობას. 
        კონფერენციაზე განიხილება კურორტების ინდუსტრიის განვითარების საჭიროება და მართვის ეფექტური საერთაშორისო მოდელები. ღონისძიება წარმოადგენს საკურორტო ინდუსტრიის განვითარების სტრატეგიული მიდგომების განხილვის შესაძლებლობის პლატფორმას
        `,
        conferance2: `
        კონფერენცია ფართოდ მიმოიხილავს კურორტების განვითარებას და მოიცავს სამ ძირითად მიმართულებას:        
        `,
        conferance3: `<ul>
        <li>
        კურორტების მდგრადი განვითარება: ტენდენციები, გამოწვევები და პერსპექტივები
        </li>
        <li>კეთილდღეობა და ბალნეოლოგია ჯანდაცვისა და კეთილდღეობის სფეროში </li>
        <li>კურორტების განვითარების ეკონომიკური და სოციალური გავლენა</li>
      </ul>
      <br />
      კონფერენციაში მონაწილეობას მიიღებენ ადგილობრივი და საერთაშორისო საკურორტო ინდუსტრიის ექსპერტები <br />
      <br />
      <b>მონაწილეები: </b> <br /> 400-მდე მონაწილე, მათ შორის სახელმწიფო და კერძო სექტორის, ადგილობრივი თვითმმართველობის, DMO-ების, საერთაშორისო ორგანიზაციებისა და აკადემიური წრეების 400-მდე წარმომადგენელი
      <br />
      <br /> <b>ოფიციალური ენები: </b>ქართული და ინგლისური`,
        footerText: `ყველა უფლება დაცულია. შექმნილია MidNight™-ის მიერ`,
        georgiaInfo1: `საქართველო ევროპისა და აზიის გზაგასაყარზე, შავი ზღვის აღმოსავლეთით მდებარეობს.  <br /> <br />
        ქვეყნის სტრატეგიული გეოპოლიტიკური მდებარეობა განაპირობებს ვიზიტორების მოზიდვას მთელი მსოფლიოდან. ეს არის მრავალფეროვანი კულტურის, რელიგიის, ლანდშაფტისა და უძველესი ისტორიის ქვეყანა<br />
        <br />`,
        georgiaInfo2: `საქართველოში 271 კურორტი და საკურორტო ადგილია. საქართველოს მდიდარი საკურორტო ლანდშაფტი მოიცავს თერმულ, ზღვის, მთის, ტბის, სათხილამურო და კლიმატურ კურორტებს, რომლებიც განლაგებულია თვალწარმტაც ადგილებში და გარშემორტყმულია იუნესკოს მემკვიდრეობისა და ისტორიულ-კულტურული ძეგლებით`,
        georgiaTitle: "საქართველოს შესახებ",
        speakers2: "მომხსენებლები",
        raedMore: "წაიკითხეთ მეტი",
        orgParag: `კურორტების განვითარების სააგენტო საქართველოს პრემიერ-მინისტრის ინიციატივით, 2022 წელს შეიქმნა და არის საქართველოს ეკონომიკისა და მდგრადი განვითარების სამინისტროს საჯარო სამართლის იურიდიული პირი. <br /> <br />
        საქართველოს ბუნებრივი და კულტურული რესურსებისა და დივერსიფიცირებული კურორტების, მათ შორის, გამაჯანსაღებელი, მთის, თერმული, ტბის და ზღვის კურორტების პოტენციალის გათვალისწინებით, საქართველოს მთავრობამ კურორტების განვითარება, ქვეყნის ეკონომიკური განვითარების ერთ-ერთ მთავარ პრიორიტეტად განსაზღვრა.
        `,
        orgParag1: `
        კურორტების განვითარების სააგენტოს მისია:
        <br /> <br />
        <ul>
          <li>
          სახელმწიფო პოლიტიკისა და ტექნიკურ-ეკონომიკური კვლევის საფუძველზე, კურორტების პოლიტიკის, განვითარების კონცეფციისა და სტრატეგიის შემუშავება;
          </li>
          <li>
          საკურორტო ინდუსტრიის გაძლიერება და ეკონომიკური ზრდისა და რეგიონული განვითარების სტიმულირება;
          </li>
          <li>
          კურორტებისა და პოტენციური საკურორტო ზონების იდენტიფიცირება და პოპულარიზაცია;
          </li>
          <li>საკურორტო ზონებში ინფრასტრუქტურის განვითარების ხელშეწყობა;</li>
          <li>
          კერძო, სამოქალაქო, საჯარო და საერთაშორისო ორგანიზაციებთან, ასევე, ადგილობრივი თვითმმართველობის ორგანოებთან მჭიდრო თანამშრომლობა;
          </li>
          <li>
          ევროკავშირის სტანდარტების, რეგულაციების, მართვის მოდელებისა და საუკეთესო პრაქტიკის დანერგვა;
          </li>
          <li>
          საგანმანათლებლო პროგრამებისა და პროფესიული სტანდარტების შემუშავება.
          </li>
        </ul>`,
        successMess: "თქვენ წარმატებით დარეგისტრირდით",
        successMessQR:
          "გთხოვთ, ღონისძიებაზე წარმოადგინოთ ელ.ფოსტაზე მიღებული QR კოდი",
        mapTitle: "კონფერენციის ადგილი",
        mapAddress: "საქართველო თბილისი 0103 თელავის ქ.20",
        contactAddress: "ა.პოლიტკოვსკაიას 7, საქართველო 0186, თბილისი",
        name: "სახელი",
        surname: "გვარი",
        country: "ქვეყანა",
        email: "ელ-ფოსტა",
        organization: "ორგანიზაცია",
        position: "პოზიცია",
        mobileNumber: "ტელეფონის ნომერი",
        invalidEmail: "ელ-ფოსტა არავალიდურია!",
        seeLess: "წაიკითხეთ ნაკლები",
        mapAddressParag: "შერატონ გრანდ მეტეხი პალასი",
        visitGeorgia: "ეწვიე საქართველოს",
        welcomeGuys: "მისასალმებელი მიმართვა",
        companyNameG: "სსიპ კურორტების განვითარების სააგენტო",
        processing: "მოთხოვნა მუშავდება...",
        welcome: "მოგესალმებით",
        thankYou: "Მადლობა მობრძანებისათვის!",
        guesNotFOund: "404 Error. სტუმარი ვერ მოიძებნა!",
        backToAdmin: "დაუბრუნდით ადმინისტრაციულ პანელს",
        successCheckIn: "თქვენ წარმატებით დარეგისტრირდით",
        checkInAgain: "თქვენ უკვე წარმატებით დარეგისტრირდით",

        events: "ღონისძიებები",
        mapAddressParag2024: "ვინდჰემ გრანდ თბილისი",
        mapAddress2024: "ლადო გუდიაშვილის ქ. 3, თბილისი 1105, საქართველო",
        backgroundImageTitle2024: "კურორტების განვითარების მომავალი",
        logisticAndTravel: "ლოგისტიკა & მოგზაურობა",
        conference2024Part1: `მე-2 საერთაშორისო კონფერენცია „კურორტების განვითარების მომავალი“ შეისწავლის კურორტების თანამედროვე დესტინაციებად გარდაქმნის ძირითად საკითხებს, კურორტების განვითარების ტენდენციებსა და გამოწვევებს. კონფერენციაზე წარმოდგენილი იქნება საკურორტო ინდუსტრიის განვითარების ხედვები და სტრატეგიები. განხილული იქნება მდგრადი მიმართულებების, ეკონომიკური ზრდის, ჯანმრთელობის, გამაჯანსაღებელი ტურიზმისა და სოციალური კეთილდღეობის საკითხები. აქცენტი ბალნეოლოგიურ და კლიმატურ კურორტებზე გაკეთდება.
`,
        conference2024Part2: `კონფერენცია მოიცავს შემდეგ თემებს: <br /> <ul>
      <li>
   კურორტების მდგრადი განვითარება: მიდგომა, რაც უზრუნველყოფს კურორტებზე ბუნებრივი რესურსების შენრჩუნებას, გარემოზე ზემოქმედების შემცირებას და  კლიმატის ცვლილებასა და სხვა გამოწვევებთან ადაპტირებული სტრატეგიების შემუშავებას. 
      </li>
      <li>კურორტების ტრანსფორმირება და მოდერნიზება: ტრადიციული კურორტების ისეთ თანამედროვე დესტინაციებად გარდაქმნა, რაც პასუხობს მოგზაურების მოთხოვნებსა და განვითარების ახალ ტენდენციებს, ამავდროულად, შეესაბამება მდგრად და  ჯანმრთელობაზე ორიენტირებულ მიდგომებს. </li>
      <li>ჯანმრთელობისა და გამაჯანსაღებელი შეთავაზებების დივერსიფიცირება: კურორტებზე გაჯანსაღების კომპონენტის ინტეგრირება, რაც კურორტს არა მხოლოდ დასვენების, არამედ ფიზიკური და მენტალური მდგომარეობის გასაუმჯობესებელ ადგილად აქცევს.</li>
        <li>ეკონომიკური სარგებლის ზრდა: ეკონომიკური განვითარების შესაძლებლობების იდენტიფიცირება, რასაც მდგრადი კურორტები შესთავაზებს როგორც ადგილობრივ მოსახლეობას, ისე საერთაშორისო ვიზიტორებსა და ტურისტებს. კურორტების მდგრადი განვითარება ხელს შეუწყობს სამუშაო ადგილების შექმნას და ქვეყნის ეკონომიკურ კეთილდღეობას.</li>
        <li>ინოვაციების დანერგვა: კურორტების მდგრადი განვითარების მისაღწევად, ინოვაციური გადაწყვეტილების,  მათ შორის, საინფორმაციო ტექნოლოგიების დანერგვა და გამოყენება.</li>
        <li>მომავლის ხედვის ჩამოყალიბება: კურორტების მდგრადობისა და გრძელვადიანი  წარმატებისთვის ხედვისა და სტრატეგიების ჩამოყალიბება, რაც, სწრაფად ცვალებად გარემოში, მათ კონკურენტუნარიანობას და მიმზიდველობას უზრუნველყოფს.</li>
        </ul>
    <br />
   კონფერენცია მოიცავს შემდეგ სესიებს:
    <br />
    <b>სესია 1:  კურორტების მდგრადი განვითარება</b> <br/>
    <b>სესია 2: გაჯანსაღების, ჯანმრთელობისა და სპა ინდუსტრიის მომავლის ფორმირება </b> </b> 
    
    <br /> კონფერენციაზე მონაწილეობას მიიღებენ ადგილობრივი და საერთაშორისო ლიდერები და დარგის ექსპერტები სამთო და სათხილამურო კურორტების სექტორებიდან. მომხსენებელთა შორის იქნებიან გამოცდილი მეცნიერები, სპეციალისტები და მკვლევრები, რომლებსაც ფართო გამოცდილება აქვთ როგორც კვლევებში, ისე კურორტების მენეჯმენტში.
    <br />
    <br /> <b>კონფერენცია ჩატარდება ინგლისურ და ქართულ ენებზე.`,

        agree1:
          "სსიპ - კურორტების განვითარების სააგენტოს მიერ შეგროვებული პერსონალური მონაცემების შემცველი ინფორმაცია კონფიდენციალურია. მონაცემები მუშავდება მხოლოდ რეგისტრაციის მიზნით „პერსონალურ მონაცემთა დაცვის შესახებ“ საქართველოს კანონის შესაბამისად. მონაცემთა სუბიექტს უფლება აქვს მიიღოს ინფორმაცია მის შესახებ დამუშავებული მონაცემების თაობაზე, მოითხოვოს მათი გასწორება, განახლება, დამატება, დაბლოკვა, წაშლა და განადგურება (შეგიძლიათ მოგვმართოთ Info@resorts.gov.ge).",
        agree2: `მეორე საერთაშორის კონფერენციის  „კურორტების განვითარების მომავალი“ 
ფარგლებში, განხორციელდება ფოტო- ვიდეო გადაღება. ვიზუალური მასალა განთავსდება ვებგვერდებსა და სოციალურ ქსელებში.
`,
        hasAgreed: "გავეცანი და ვეთანხმები",


        moderators: "მოდერატორები"
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
