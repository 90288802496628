export const agendaEng2024 = [
  {
    id: 0,
    date: "10:00 – 11:00",
    title: `Registration of participants <br />
      Welcome Coffee`,
  },
  {
    id: 1,
    date: "11:00 – 11:20",
    title: `Opening Ceremony and Welcoming Address <br /><br />
    Levan Davitashvili - Vice Prime Minister, Minister of Economy and Sustainable Development of Georgia <br /><br />
    Aleksandre Onoprishvili - Director, LEPL Resorts Development Agency`,
  },
  {
    id: 2,
    date: " 11:20 - 13:00",
    title: " Session 1: Modernizing Resorts into Sustainable Destinations",
    parag: `
   The session focuses on transforming resorts into sustainable destinations. It 
explores strategies to modernize resorts while maintaining economic, 
environmental, social and cultural sustainability. Discussions will cover 
innovations in infrastructure, energy efficiency, spatial planning, urban 
development, and eco-friendly practices that enhance the visitor experience 
without compromising the natural or cultural integrity of the locations. The 
session will highlight how modern resort developments are adapted to 
address contemporary challenges in order to ensure their sustainable growth.
    <br /><br /> 
    <b>Moderator: TBC, Maia Omiadze</b><br/> 
Speakers:</b> <br />
`,
    list: [
      `Nino Tandilashvili – First Deputy Minister, Ministry of Environment and
 Agriculture Development of Georgia <br />
 “The Development of Ecotourism within the Protected Area of Georgia”`,
      `PhD Maria Pia Ancora – Senior Urban Development Specialist, ADB Sectors
 Group <br />
 “Planning for Resilient Destinations: Urban Strategies for Environmental
 and Climate Resilience”`,
      `Dr. Ioannis Pappas – Director, Mediterranean Region of the Global
 Sustainable Tourism Council (GSTC) <br />
 “Sustainable Tourism and Destinations – The role GSTC”`,
      // `Benoît ROBERT - President, Cluster Montagne France`,
      `Dr. Kenichi Hashimoto – Chairman, 9steps Corporation of Japan. <br /> "Evolution of Resort"`,
    ],
    qa: "Q/A",
  },
  {
    id: 3,
    date: "13:00 – 13:45",
    title: "Lunch Break",
  },
  {
    id: 4,
    date: "13:45 – 15:30",
    title: "Session 2. Shaping the future of Wellness, Health and Spa Industry",
    parag: `This session will explore emerging trends and innovations in the wellness, 
health, and spa industry, focusing on how these sectors can evolve to meet 
growing global demands. It will discuss how resorts can enhance their services 
to promote better health and well-being. Furthermore, the session will highlight 
how to shape the future of resorts so that wellness and health-focused 
destinations remain competitive, resilient, and aligned with global health and 
sustainability standards. <br /><br /> Moderator:  Assoc. Professor MUDr. Jiří Dostal – CEO, Center for Sports Medicine of 
Czech Republic; Lecturer, Charles University<br /><br /> 
    Speakers:</b> <br />
    `,
    list: [
      `Assoc. Professor Lela Potskhverashvili – Vice Director, Resorts Development
 Agency of Georgia`,
      `Csilla Mezősi –Secretary General, European Spas Association (ESPA)`,
      `Dr. Kurt von Storch – CEO, EuropeSpa med & wellness GmbH, Wiesbaden;  Vice
President, Academy of Balneology and Climatology of Germany <br />
 “The path to international markets - the international "EuropeSpa" standard
 for Medical Spas and the "European Health Destinations Standard" for Spa
 Towns”`,
      `Dr. Marcus Coplin – President, the Balneology Association of North America
 (BANA) <br />
 "Creating an International Guestlist: Authentic Resort Culture, Local
 Identity, and Enhanced Programming."`,
      /////////////////////
      // `Presentation of the Project REVIVE (Portugal) TBC`,
    ],
    qa: "Q/A",
  },
  {
    id: 5,
    date: "15:30",
    title: "End of the Program",
  },
];
export const agendaGeo2024 = [
  {
    id: 0,
    date: "10:00 – 11:00",
    title: `მონაწილეთა რეგისტრაცია`,
  },
  {
    id: 1,
    date: "11:00 – 11:20",
    title: `კონფერენციის გახსნა <br /><br />
    ლევან დავითაშვილი - საქართველოს ვიცე-პრემიერი, ეკონომიკისა და მდგრადი განვითარების მინისტრი <br /><br />
    ალექსანდრე ონოფრიშვილი - სსიპ კურორტების განვითარების სააგენტოს დირექტორი`,
  },
  {
    id: 2,
    date: " 11:20 - 13:00",
    title: "სესია 1: კურორტების  მდგრადი განვითარება",
    parag: `
  სესია განიხილავს კურორტების მდგრად დესტინაციებად გარდაქმნას. კურორტების მოდერნიზაციის სტრატეგიები ეკონომიკურ,  გარემოსდაცვით, სოციალური და კულტურული მდგრადობის ჭრილში იქნება განხილული.  დისკუსია მოიცავს ინოვაციებს  ინფრასტრუქტურაში, სივრცით დაგეგმარებასა და  ურბანულ განვითარებაში. სესიაზე წარმოდგენილი იქნება მაგალითები ვიზიტორების ეკომეგობრულ გამოცდილებაზე, სადაც კურორტების ბუნებასა და კულტურულ მემკვიდრეობაზე ზემოქმედება მინიმალურია. ასევე, განიხილება კურორტების განვითარების თანამედროვე ტენდენციები, მდგრადი განვითარების წინაშე არსებული გამოწვევები და მათი გადაჭრის გზები.
    <br /><br /> 
    <b>მოდერატორი: TBC, მაია ომიაძე</b><br/> 
მომხსენებელი:</b> <br />
`,
    list: [
      `ნინო თანდილაშვილი – გარემოს დაცვისა და სოფლის მეურნეობის მინისტრის პირველი მოადგილე
„ეკოტურიზმის განვითარება საქართველოს დაცული ტერიტორიების ფარგლებში“
`,
      `დოქტორი მარია პია ანკორა – ADB Sectors Group-ის ურბანული განვითარების უფროსი სპეციალისტი
”მდგრადი მიმართულებების დაგეგმვა: ურბანული სტრატეგიები გარემოსა და კლიმატის მდგრადობისთვის”

`,
      `დოქტორი იოანის პაპასი - გლობალური მდგრადი ტურიზმის საბჭოს (GSTC) ხმელთაშუა ზღვის რეგიონის დირექტორი. 
<br /> "მდგრადი ტურიზმი და კურორტები - GSTC- ის როლი"
`,
      `დოქტორი კენიჩი ჰაშიმოტო – 9steps Corporation of Japan-ის თავმჯდომარე. <br /> "კურორტების ევოლუცია"`,
    ],
    qa: "კითხვა - პასუხი",
  },
  {
    id: 3,
    date: "13:00 – 13:45",
    title: "სადილი",
  },
  {
    id: 4,
    date: "13:45 – 15:30",
    title:
      "სესია 2. გაჯანსაღების, ჯანმრთელობისა და სპა ინდუსტრიის მომავლის ფორმირება",
    parag: `სესიაზე განიხილება გაჯანსაღების, ჯანმრთელობისა და სპა ინდუსტრიის განვითარების ტენდენციები, ინოვაციები და მზარდ გლობალურ მოთხოვნებთან სექტორის ადაპტირება.  წარმოდგენილი იქნება საზოგადოების კეთილდღდეობის გაუმჯობესების ხელშემწყობი კურორტების პროდუქტები და სერვისები. ამასთანავე,  სესია წარმოაჩენს გაჯანსაღებასა და კეთილდღეობაზე ორიენტირებული კონკურენტუნარიანი კურორტების მომავლის ფორმირებას მდგრადობისა და ჯანმრთელობის საერთაშორისო სტანდარტების დაცვით.  <br /><br />
    <b>მოდერატორი: <b/> ასოცირებული პროფესორი, მედიცინის დოქტორი ირი დოსტალი – ჩეხეთის სპორტული მედიცინის ცენტრის აღმასრულებელი დირექტორი და ჩარლზის უნივერსიტეტის ლექტორი<br /><br />

    მომხსენებლები :</b> <br />
    `,
    list: [
      `ასოცირებული პროფესორი ლელა ფოცხვერაშვილი - სსიპ კურორტების განვითარების სააგენტოს დირექტორის მოადგილე`,
      `ჩილა მეზოსი – ევროპის სპა ასოციაციის (ESPA) გენერალური მდივანი`,
      `დოქტორი კურტ ფონ სტორჩი – EuropeSpa med & wellness GmbH, Wiesbaden-ის აღმასრულებელი დირექტორი; გერმანიის ბალნეოლოგიისა და კლიმატოლოგიის აკადემიის ვიცე-პრეზიდენტი
 „გზა საერთაშორისო ბაზრებისკენ - საერთაშორისო სტანდარტები Eurospa და European Health Destinations Standard სპა ქალაქებისა და სამედიცინო სპა ცენტრებისთვის“

`,
      `მარკუს კოპლინი – ჩრდილოეთ ამერიკის ბალნეოლოგიის ასოციაციის (BANA) პრეზიდენტი
 „საერთაშორისო ვიზიტორების განსაზღვრა: კურორტების ავთენტური  კულტურა, ადგილობრივი იდენტობა და პროდუქტები.

`,
      /////////////////////
      // `Presentation of the Project REVIVE (Portugal) TBC`,
    ],
    qa: "კითხვა - პასუხი",
  },
  {
    id: 5,
    date: "15:30",
    title: "კონფერენციის დასრულება",
  },
];
