import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import AllLogoGEo from "../assets/images/All logos eco (1)-05-05.png";
import AllLogoEng from "../assets/images/3-03.png";

import { useEffect, useState } from "react";
import i18n from "../i18n";

import Pin from "../assets/images/pin.png";
import Email from "../assets/images/email.png";
import Call from "../assets/images/call.png";

function Footer() {
  const { t } = useTranslation();

  const [currentLang, setCurrentLang] = useState(
    localStorage.getItem("lang") || "en"
  );

  useEffect(() => {
    setCurrentLang(localStorage.getItem("lang"));
  }, [i18n.language]);

  return (
    <footer
      className="footer text-light text-center py-4 mt-5"
      style={{
        backgroundColor: "transparent",
      }}
    >
      <div className="row mx-auto mt-4" style={{ maxWidth: "90%" }}>
        <div id="contact">
          <div>
            {/* <img
            className="background-image"
            src={BackgrounHalf}
            alt="Background Image"
          /> */}
          </div>
        </div>
        <div className="col-lg-6 text-lg-start">
          <div className="">
            <h3
              className="ps-4 pe-4 d-none"
              style={{ color: "rgb(252, 212, 41)" }}
            >
              {t("navbarContact")}
            </h3>
            <div className="" style={{ color: "white" }}>
              <h2 className="mb-3" style={{ color: "rgb(252, 212, 41)" }}>
                {t("companyNameG")}
              </h2>
              <div className="p-2">
                <p>
                  <img
                    src={Pin}
                    alt=""
                    className="me-1"
                    width={"16px"}
                    height={"16px"}
                  />{" "}
                  {t("contactAddress")}
                </p>
                <p>
                  <img
                    src={Call}
                    className="me-2"
                    alt=""
                    width={"16px"}
                    height={"16px"}
                  />
                  +995 32 2 50 03 38
                </p>
                <p>
                  <img
                    src={Email}
                    className="me-2"
                    alt=""
                    width={"16px"}
                    height={"16px"}
                  />
                  conference@resorts.gov.ge
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-4 text-lg-end">
          <img
            src={currentLang === "en" ? AllLogoEng : AllLogoGEo}
            alt=""
            usemap="#image-map"
            className="footer-images"
            style={{
              marginTop: currentLang !== "en" ? "-12px" : "-6px",
              width: "300px",
            }}
          />
        </div>

        <map name="image-map">
          <area
            shape="rect"
            coords="0,0,150,60"
            href={
              currentLang === "en"
                ? "https://www.economy.ge/?lang=en"
                : "https://www.economy.ge/?lang=ge"
            }
            target="_blank"
            alt="Ministry of Economy"
          />

          <area
            shape="rect"
            coords="160,0,400,60"
            href="https://resorts.gov.ge/"
            target="_blank"
            alt="LEPL Resorts Development"
          />
        </map>
        <div
          class="col-12 footer-text-copyright text-center pt-4"
          style={{ borderTop: "1px solid gray" }}
        >
          <div>&#169; {t("footerText")}</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
