import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import Checked from "../assets/images/checked.png";
import NeedAssistant from "../assets/images/need-assessment.png";
import { useTranslation } from "react-i18next";

function isGuid(str) {
  const guidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidRegex.test(str);
}

function CheckIn() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const oid = searchParams.get("oid");

  const [result, setResult] = useState();
  const [loader, setLoader] = useState(false);

  const guestHasAttended = async () => {
    try {
      setLoader(true);
      const token = localStorage.getItem("token");
      const response = await axios.get("api/home/GuestHasAttended?oid=" + oid, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      debugger;
      setLoader(false);
      setResult(response.data);
    } catch (error) {
      setLoader(false);
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (isGuid(oid)) guestHasAttended();
  }, [oid]);

  const { t } = useTranslation();

  return (
    <div className="check-in text-center mt-5 p-3">
      <h3>{t("welcome")}</h3>
      {result?.guestName && result?.guestName !== "" && (
        <h4>{result?.guestName}</h4>
      )}
      <h4 className="mt-5">{t("thankYou")}</h4>
      {loader && (
        <>
          <div>{t("processing")}</div>
          <div className="mt-2">
            <div className="loader mx-auto"></div>
          </div>
        </>
      )}
      {!loader && (
        <>
          {result?.guestName && (
            <>
              <h4>
                {result?.hasAttended === true
                  ? t("checkInAgain")
                  : t("successCheckIn")}
              </h4>
              <img
                className="mt-3"
                src={result?.hasAttended !== true ? Checked : NeedAssistant}
                alt=""
                width="80px"
              />
            </>
          )}
          {(result === 404 || !isGuid(oid)) && (
            <>
              <h4 className="text-danger">{t("guesNotFOund")}</h4>
            </>
          )}
          <div className="pt-5">
            <Link to={"/admin-panel"}>{t("backToAdmin")}</Link>
          </div>
        </>
      )}
    </div>
  );
}

export default CheckIn;
